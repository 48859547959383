import './index.css'
import { useEffect, useState } from 'react'
// import { CSVLink } from 'react-csv'
import { Button, Col, Input, Row, Tooltip } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { Member } from '../../../../domain/entities/Member'
// import moment from 'moment'
import { annualDividendColumns } from './utils'
import { DividendFrequency } from '../../../../domain/entities/SystemSettings'
// import { capitalize } from '../../utils'
// import { useAppSelector } from '../../../store'
// import { shallowEqual } from 'react-redux'
import Table from '../../../components/Table'
import { FaUserEdit } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
// import { TeamMember } from '../../../../domain/entities/Team'

// const Menu = ({ data, columns }: { data: Member[]; columns: any[] }) => {
//     const styles = useAppSelector((state) => state.styles, shallowEqual)
//     const { xs } = Grid.useBreakpoint()
//     const [headers, setHeaders] = useState<any>([])
//     useEffect(() => {
//         if (!data.length) return
//         const keys = Object.keys(data[0])
//         const hdrs: { key: string; label: string }[] = []

//         keys.forEach((key) => {
//             const idx = columns.findIndex((column) => column.dataIndex === key)
//             if (idx < 0) return

//             hdrs.push({
//                 key,
//                 label: capitalize(key),
//             })
//         })
//         setHeaders(hdrs)
//     }, [data, columns])

//     const date = moment().format('MMM YYYY')
//     return (
//         <CSVLink
//             filename={`${date} - Receivables`}
//             data={data}
//             headers={headers}
//         >
//             {xs ? (
//                 <Button
//                     icon={<FileExcelOutlined style={{ fontSize: 18 }} />}
//                     style={{
//                         backgroundColor: styles.primary,
//                         color: 'white',
//                     }}
//                     size="large"
//                 ></Button>
//             ) : (
//                 <Button
//                     icon={<FileExcelOutlined style={{ fontSize: 18 }} />}
//                     style={{
//                         backgroundColor: styles.primary,
//                         color: 'white',
//                     }}
//                     size="large"
//                 >
//                     Export to Excel
//                 </Button>
//             )}
//         </CSVLink>
//         // <Menu>
//         //     <Menu.Item
//         //         key="1"
//         //         icon={
//         //             <FileExcelOutlined
//         //                 style={{ fontSize: 18, color: 'green' }}
//         //             />
//         //         }
//         //     >
//         //         <CSVLink
//         //             filename={`${date} - Receivables`}
//         //             headers={headers}
//         //             data={data}
//         //         >
//         //             To Excel Spreadsheet
//         //         </CSVLink>
//         //     </Menu.Item>
//         //     {/*<Menu.Item*/}
//         //     {/*    key="2"*/}
//         //     {/*    icon={*/}
//         //     {/*        <FilePdfOutlined*/}
//         //     {/*            style={{ fontSize: 18, color: 'rgb(19,121,170)' }}*/}
//         //     {/*        />*/}
//         //     {/*    }*/}
//         //     {/*>*/}
//         //     {/*    To PDF*/}
//         //     {/*</Menu.Item>*/}
//         // </Menu>
//     )
// }

const MembersReceivableTable = ({
    data,
    shareDividendFrequency,
}: {
    data: Array<Member>
    currentMonth: string
    shareDividendFrequency: DividendFrequency
}) => {
    const [filteredMembers, setFilteredMembers] = useState<any>([])
    const [toSearch, setToSearch] = useState('')
    const handleSearch = (e: string) => {
        setToSearch(e)
    }

    useEffect(() => {
        let timeout: any
        if (toSearch) {
            timeout = setTimeout(() => {
                const copied = [...data]
                const filtered = copied.filter((member) => {
                    const splittedTitle =
                        (member.displayName as string) ||
                        (member.email as string)
                    return (
                        splittedTitle
                            .toLowerCase()
                            .replace(/ /g, '')
                            .includes(toSearch) ||
                        splittedTitle.toLowerCase().includes(toSearch)
                    )
                })
                setFilteredMembers(filtered)
            }, 100)
        } else {
            setFilteredMembers(data)
        }
        return () => {
            timeout && clearTimeout(timeout)
        }
    }, [data, toSearch])
    const navigate = useNavigate()
    return (
        <div>
            <Row
                align={'middle'}
                wrap={false}
                gutter={[
                    {
                        xs: 6,
                        md: 12,
                    },
                    {
                        xs: 6,
                        md: 12,
                    },
                ]}
                style={{
                    padding: '16px 0',
                }}
            >
                <Col>
                    <Input
                        value={toSearch}
                        onChange={(e) => handleSearch(e.target.value)}
                        allowClear
                        placeholder={'Search by name'}
                        suffix={
                            <Button icon={<SearchOutlined />} type="link" />
                        }
                        // addonBefore={xs ? null : <>Search name</>}
                        size="large"
                        style={{}}
                    />
                </Col>

                {/* <Col>
                    <Menu
                        data={filteredMembers}
                        columns={
                            currentMonth === 'Annual'
                                ? annualDividendColumns
                                : membersReceivableColumns
                        }
                    />
                </Col> */}
            </Row>
            {/* <div style={{ overflow: 'auto' }}> */}
            <Table
                dataSource={filteredMembers}
                columns={[
                    ...annualDividendColumns,
                    {
                        title: 'Actions',
                        render: (row, record) => {
                            return (
                                <Row>
                                    <Col>
                                        <Tooltip title={'Update Member'}>
                                            <Button
                                                type="link"
                                                onClick={() => {
                                                    navigate(
                                                        `update-member/${record.id}`,
                                                        {
                                                            state: record,
                                                        }
                                                    )
                                                }}
                                            >
                                                <FaUserEdit
                                                    style={{ fontSize: 20 }}
                                                />
                                            </Button>
                                        </Tooltip>
                                    </Col>
                                    <Col></Col>
                                </Row>
                            )
                        },
                    },
                ]}
                columnsState={{
                    persistenceType: 'localStorage',
                    persistenceKey: shareDividendFrequency,
                }}
                toolBarRender={false}
                scroll={{ x: 800 }}
            />
            {/* </div> */}
        </div>
    )
}

export default MembersReceivableTable
