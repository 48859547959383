import { Row, Col } from 'antd'
import { withTranslation } from 'react-i18next'
import { SvgIcon } from '../../common/SvgIcon'
import Container from '../../common/Container'

// import i18n from 'i18next'
import {
    FooterSection,
    // Title,
    // NavLink,
    // Extra,
    // LogoContainer,
    Para,
    Large,
    // Chat,
    // Empty,
    FooterContainer,
    // Language,
    // Label,
    // LanguageSwitch,
    // LanguageSwitchContainer,
} from './styles'
import { CopyrightCircleOutlined } from '@ant-design/icons'

interface SocialLinkProps {
    href: string
    src: string
}

const Footer = ({ t }: any) => {
    // const handleChange = (language: string) => {
    //     i18n.changeLanguage(language)
    // }

    const SocialLink = ({ href, src }: SocialLinkProps) => {
        return (
            <a
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                key={src}
                aria-label={src}
            >
                <SvgIcon src={src} width="25px" height="25px" />
            </a>
        )
    }

    return (
        <div id="footer">
            <FooterSection>
                <Container>
                    <Row >
                        <Col>
                            <Large
                                onClick={() => {
                                    if (
                                        // @ts-ignore
                                        window.Tawk_API
                                    ) {
                                        // @ts-ignore
                                        // eslint-disable-next-line no-console
                                        console.log(window.Tawk_API)

                                        // @ts-ignore
                                        window.Tawk_API.maximize()
                                    }
                                }}
                            >
                                {t('Tell us everything')}
                            </Large>
                            <Row wrap={false} gutter={[12, 12]}>
                                <Col>
                                    <Para style={{ width: '100%' }}>
                                        {t(`Send an email: `)}{' '}
                                    </Para>
                                </Col>
                                <Col>
                                    <a href="mailto: hello@perati.co">
                                        helpdesk@perati.co	
                                    </a>
                                </Col>
                            </Row>
                            <br />

                            <Row wrap={false} gutter={[12, 12]}>
                                <Col>
                                    <Para style={{ width: '100%' }}>
                                        {t(`Leave a message: `)}{' '}
                                    </Para>
                                </Col>
                                <Col>
                                    <a href="tel:+639399033470">
                                        0939 903 3470
                                    </a>
                                </Col>
                            </Row>
                            <br />
                            
                            <Row>
                                <Col>
                                    <Para>#2047, Seminary Road, C.D.O.,</Para>
                                    <Para>Misamis Oriental, 9000</Para>
                                    <Para>Philippines</Para>
                                </Col>
                            </Row>
                            <br />

                            <Row>
                                <Col>
                                <SocialLink
                                href="https://www.facebook.com/profile.php?id=100095090563720"
                                src="facebook.svg"
                            /></Col>
                            </Row>
                        </Col>
                        
                    </Row>
                    <br />
                    <Row justify={'center'} align={'middle'}>
                        <Col>
                        <FooterContainer>
                        <code>
                            <CopyrightCircleOutlined /> 2025 • PERATI • All
                            rights reserved
                        </code>
                    </FooterContainer>
                        </Col>
                    </Row>
                </Container>
            </FooterSection>
        </div>
    )
}

export default withTranslation()(Footer)
