import React from 'react';
import PropTypes from 'prop-types';
import TweenOne from 'rc-tween-one';
// import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';
// import Icon from '@ant-design/icons';
import {   Typography } from 'antd';
import QueueAnim from 'rc-queue-anim';
import RcScrollElement from 'rc-scroll-anim/lib/ScrollElement';

export default function Page1({ isMobile }) {
  return (
    <RcScrollElement id="page1-landing" className="content-wrapper page">
      <TweenOne
        key="image"
        className="image1 image-wrapper"
        animation={{ x: 0, opacity: 1, ease: 'easeOutQuad' }}
        style={{ transform: 'translateX(-100px)', opacity: 0 }}
      />
      <QueueAnim
        type={isMobile ? 'bottom' : 'right'}
        className="text-wrapper"
        key="text"
        leaveReverse
      >
        <h1 style={{ fontWeight: 'normal', fontSize: 48 }}>HOW IT WORKS</h1>
        <br/>
        <br/>
        
        <Typography.Paragraph
            type={'secondary'}
            style={{
                fontSize: 24,
                marginBottom: 0
            }}
        >
            01
        </Typography.Paragraph>
        <br/>

        <h2 key="h2">Convenient</h2>
        <p key="p" style={{ maxWidth: 310 }}>Reloading made easy, enabling quick and easy adoption by commuters for a seamless fare experience.</p>
        {/* <div key="button">
          <a>
            <Button type="primary" size="large">
              WAH
              <Icon type="right" />
            </Button>
          </a>
        </div> */}
      </QueueAnim>
    </RcScrollElement>
  );
}
Page1.propTypes = {
  isMobile: PropTypes.bool,
};
