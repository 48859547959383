import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Row, Col, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
// import { getMyTeam, } from '../../../redux/team/team.action';
// import { updateTeamState } from '../../../redux/team/team.slice';

// const searchEngine = 'Google';

const ClientHeader = ({ isFirstScreen, isMobile }) => {
  // const [menuVisible, setMenuVisible] = useState(false);


  const navigate = useNavigate()
  // const onMenuVisibleChange = (visible) => {
  //   setMenuVisible(visible);
  // };

  // const handleShowMenu = () => {
  //   setMenuVisible(true);
  // };

  // const handleHideMenu = () => {
  //   setMenuVisible(false);
  // };

  // const handleSelectFilter = (value, option) => {
  //   const optionValue = option.props['data-label'];
  //   return (
  //     optionValue === searchEngine ||
  //     optionValue.indexOf(value.toLowerCase()) > -1
  //   );
  // };

  const headerClassName = classNames({
    clearfix: true,
    'home-nav-white': !isFirstScreen,
  });
  // const dispatch = useAppDispatch()
  // useEffect(() => {
  //   const split = window.location.hostname.split('.')
  //   const host = split[0]
  //   const listener = getMyTeam(host, (resp) => {
  //     dispatch(
  //       updateTeamState({
  //         selectedTeamData: resp,
  //       })
  //     )
  //   })
  //   return () => listener()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  return (
    <header id="header" className={headerClassName} style={{ padding: isMobile ? "0 12px" : undefined }}>
      <nav
        style={{
          height: 80,
          padding: isMobile ? 0 : '24px 40px 24px',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Row justify="space-between" style={{ width: '100%' }} wrap={false}>
          <Col xs={8}>
            <div style={{ cursor: 'pointer' }} onClick={() => navigate("/")}>
              {/* {logoUrl && ( */}
              <Row wrap={false} gutter={[12, 12]}>
                <Col  >
                <img
                src={'/logo192.png'}
                width={isMobile ? "100%" : 'auto'}
                height={isMobile ? "100%" : 60}
                alt={'Upload your logo'}
              />
                </Col>
                <Col style={{ 
                  display: isMobile ? 'none' : 'block'
                }}>
                </Col>
</Row>
              {/* )} */}
            </div>
          </Col>
          <Col style={{ display: 'flex', alignItems: 'center' }}>
            <Row align="middle">
              <Col>
                <Button size="large" shape="round" onClick={() => navigate('/login')}>
                  Log in
                </Button>
              </Col>
              <Col>
                {/* <Button size="large" shape="round" onClick={() => navigate('/register')}>
                  Sign up
                </Button> */}
              </Col>
            </Row>
          </Col>
        </Row>
      </nav>
    </header>
  );
};

ClientHeader.propTypes = {
  isFirstScreen: PropTypes.bool,
  isMoblie: PropTypes.bool,
};

export default ClientHeader;