import { RouterProvider, createBrowserRouter } from 'react-router-dom'
// import { Switch } from 'react-router'
// import ProtectedRoute from './routes/ProtectedRoute'
import Login from './pages/Login/Login'
// import MemberContainer from './pages/Member/MemberContainer'
// import SettingsContainer from './pages/Settings/SettingsContainer'
// import ReportContainer from './pages/Reports/ReportContainer'
// import AddMember, { getRoleValue } from './pages/Member/AddMember'
// import UpdateMember from './pages/Member/UpdateMember'
// import ViewMemberLoans from './pages/Member/loans/ViewMemberLoans'
// import SettingsContainer from './pages/Settings/SettingsContainer'
// import ExpensesContainer from './pages/Expenses/ExpensesContainer'
// import { isLoggedIn } from './redux/auth/auth.slice'
// import { useAppDispatch } from './store'
// import UserRegister from './pages/Register/Registeration'
// import MyTeam from './pages/My/MyTeam'
import Invite from './pages/Invite/Invite'
// import SharesContainer from './pages/Shares/SharesContainer'
// import OnboardTeam from './pages/My/OnboardTeam'
// import LoansContainer from './pages/Reports/Loans/LoansContainer'
// import MembersReceivableContainer from './pages/Reports/MembersReceivableReport/MembersReceivableContainer'
// import BorrowersContainer from './pages/Borrowers/BorrowersContainer'
// import PaymentContainer from './pages/Borrowers/Payments/BorrowerPaymentContainer'
import ForgotPage from './pages/ForgotPasword/ForgotPage'
// import PayLoan from './pages/Member/loans/components/PayLoan'
// import DashboardLayout from './pages/Dashboard/DashboardLayout'
// import SavingsContainer from './pages/Savings/SavingsContainer'
import LandingHome from './pages/Home/LandingHome'
// import Branch from './pages/Settings/components/Branch'
// import TextBlast from './pages/Settings/components/TextBlast'
// import VehiclesDriversContainer from './pages/VehiclesDriver/VehiclesDriverContainer'
// import Vehicle from './pages/VehiclesDriver/Vehicle'
// import Driver from './pages/VehiclesDriver/Driver'
// import PersonnelContainer from './pages/Personnel/PersonnelContainer'
// import PersonnelDashboard from './pages/Personnel/PersonnelDashboard'
// import Payroll from './pages/Accounting/Payroll'
// import ChangePassword from './pages/My/ChangePassword'
// import AutoSuggest from './pages/Scheduling/AutoSuggest'
// import Scheduling from './pages/Scheduling/Scheduling'
// import RecentShifts from './pages/Scheduling/RecentShift'
import PrivacyPolicy from './components/PrivacyPolicy'
import DeleteAccountRequest from './components/DeleteAccountRequest'
// import AppRoutes from './routes/AppRoute'
// import { Image } from 'antd'
// import { positions } from '@/data/repositories/constants'
import { getUserRoute } from './routes/helpers'
import { useEffect, useState } from 'react'
import { shallowEqual } from 'react-redux'
import { useAppDispatch, useAppSelector } from './store'
// import { cashierRoutes } from './routes'
// import CashierDashboard from './pages/Cashier/CashierDashboard'
import ProtectedRoute from './routes/ProtectedRoute'
import {
    bookKeeperRoutes,
    cashierRoutes,
    // chairmanRoutes,
    defaultRoutes,
    dispatcherRoutes,
    fleetMgrRoutes,
    // gmRoutes,
    hrRoutes,
    itRoutes,
    memberRoutes,
    payrollSpecialistRoutes,
    protectedRoutes,
    teamLeaderRoutes,
    treasurerRoutes,
} from './routes'
import PublicRoute from './routes/PublicRoute'
import { isLoggedIn, setAuthState } from './redux/auth/auth.slice'
import MemberRoute from './routes/MemberRoute'
import UserRegister from './pages/Register/Registeration'
import Success from './pages/Home/components/ContactForm/Success'
import ProductPage from './pages/Store/ProductPage'
import OrderPage from './pages/Store/OrderPage'
import SuccessOrder from './pages/Store/SuccessOrder'
import FailureOrder from './pages/Store/FailureOrder'
const init = [
    {
        path: '/',
        element: <LandingHome />,
    },
    {
        path: '/app',
        element: <UserRegister />,
    },
    {
        path: '/invite/:teamId',
        element: <Invite />,
    },
    {
        path: '/terms-and-conditions',
        element: <PrivacyPolicy />,
    },
    {
        path: '/privacy-policy',
        element: <PrivacyPolicy />,
    },
    {
        path: '/login',
        element: <Login />,
    },
    {
        path: '/contact-success',
        element: <Success />,
    },
    {
        path: '/forgot',
        element: <ForgotPage />,
    },
    {
        path: '/delete-request-public',
        element: <DeleteAccountRequest />,
    },
    {
        path: '/store/:productId',
        element: <ProductPage />,
    },
    {
        path: '/store/:productId/order',
        element: <OrderPage />,
    },
    {
        path: '/store/:productId/order/success',
        element: <SuccessOrder />,
    },
    {
        path: '/store/:productId/order/failure',
        element: <FailureOrder />,
    },
    {
        path: '*',
        element: <LandingHome />,
    },
]

const initialRoutes = init.map((path) => {
    return {
        ...path,
        element: <PublicRoute>{path.element}</PublicRoute>,
    }
})
const RouteManager = () => {
    const [routes, setRoutes] = useState(initialRoutes)
    const { userRole, isLoggedInUser, uid } = useAppSelector(
        (state) => ({
            // @ts-ignore
            userRole: state.auth?.user?.role || '',
            isLoggedInUser: state?.auth?.isLoggedIn || false,
            uid: state?.auth?.user?.id || '',
            // activeTeam: state.auth.user.activeTeam,
            // selectedTeamData: state.team.selectedTeamData,
            // data: state.vehicle.selectedVehicleId
            //     ? state.vehicle.vehicles.entities[
            //           state.vehicle.selectedVehicleId
            //       ]
            //     : undefined,
            // selectedVehicleId: state.vehicle.selectedVehicleId,
        }),
        shallowEqual
    )
    // eslint-disable-next-line no-console
    // console.log(isLoggedInUser)
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (isLoggedInUser) {
            dispatch(
                isLoggedIn((callback) => {
                    // eslint-disable-next-line no-console
                    // console.log(isLoggedInUser)

                    dispatch(
                        setAuthState({
                            user: callback,
                        })
                    )
                })
            )
        }
        // @ts-ignore
        if (isLoggedInUser && userRole) {
            // @ts-ignore
            const route = getUserRoute(userRole)
            let paths: any = []
            // @ts-ignore
            // if (userRole === 'general_manager') return routes
            // @ts-ignore
            let wrapperElement: any
            let fullPath = `/app/${route}`
            if (userRole === 'chairman') {
                paths = protectedRoutes
                // @ts-ignore
            } else if (userRole === 'cashier') {
                paths = cashierRoutes
                // @ts-ignore
            } else if (userRole === 'team_leader') {
                paths = teamLeaderRoutes
                // @ts-ignore
            } else if (userRole === 'treasurer') {
                paths = treasurerRoutes
            } else if (userRole === 'book_keeper') {
                paths = bookKeeperRoutes
            } else if (userRole === 'dispatcher') {
                paths = dispatcherRoutes
            } else if (userRole === 'fleet_manager') {
                paths = fleetMgrRoutes
            } else if (userRole === 'payroll_specialist') {
                paths = payrollSpecialistRoutes
            } else if (userRole === 'accounting_manager') {
                paths = treasurerRoutes
            } else if (userRole === 'general_manager') {
                paths = protectedRoutes
            } else if (userRole === 'hr_manager') {
                paths = hrRoutes
            } else if (userRole === 'it_support') {
                paths = itRoutes
            } else if (userRole === 'regular_member') {
                paths = memberRoutes

                fullPath = `/app/u`
                wrapperElement = <MemberRoute paths={paths} />
            } else {
                paths = defaultRoutes
            }
            if (userRole !== 'regular_member') {
                wrapperElement = <ProtectedRoute paths={paths} />
            }

            const finalRoute = {
                path: fullPath,
                element: wrapperElement,
                children: paths,
            }
            // eslint-disable-next-line no-console
            // console.log(finalRoute, paths);
            setRoutes([...routes, finalRoute])
        }
        // @ts-ignore
        if (!isLoggedInUser && (userRole === 'driver' || !userRole)) {
            setRoutes(initialRoutes)
            // setRoutes([])
        }
        // @ts-ignore
    }, [isLoggedInUser, userRole, uid])
    const router = createBrowserRouter(routes)
    return <RouterProvider router={router} />
}

export default RouteManager
