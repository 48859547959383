import 'intro.js/introjs.css'
import './index.css'
import {
    Button,
    Col,
    Dropdown,
    Input,
    Popconfirm,
    Row,
    // Table,
    Tooltip,
    Typography,
    message,
} from 'antd'
import {
    FilterFilled,
    // ArrowLeftOutlined,
    MinusCircleOutlined,
    SearchOutlined,
} from '@ant-design/icons'
// import AddTeammateButton from './components/AddTeammateButton'
import { useAppSelector } from '../../../store'
import {
    // getTeamMembers,
    removeTeamMember,
    // updateTeamMember,
} from '../../../redux/team/team.action'
import { useNavigate } from 'react-router-dom'
import { shallowEqual } from 'react-redux'
import { TeamMember } from '../../../../domain/entities/Team'
// import moment from 'moment'
import { useEffect, useState } from 'react'
import { FaUserClock, FaUserEdit } from 'react-icons/fa'
import {
    ModalForm,
    ProForm,
    ProFormDatePicker,
    ProFormDigit,
    ProFormTimePicker,
} from '@ant-design/pro-components'
import {
    PERSONNEL_REPOSITORY,
    employeeStatus,
    // TRIPS_REPOSITORY,
    // VEHICLE_REPOSITORY,
    positions,
} from '../../../../data/repositories/constants'
import {
    query,
    collection,
    where,
    getDocs,
    addDoc,
    updateDoc,
    // getDoc,
    // onSnapshot,
} from 'firebase/firestore'
import moment from 'moment'
import { db } from '../../../boot'
import { Personnel } from '../../../../domain/entities/Transport'
import Table from '../../../../app/components/Table'
import { Attendance } from '@/domain/entities/Attendance'
import EmployeeAvatar from '../../../../app/components/EmployeeAvatar'
import { calculateWorkTime } from './hrUtils'

const MyPersonnel = () => {
    const { currentEmployees, activeTeam, email, styles, hr } = useAppSelector(
        (state) => ({
            currentEmployees: state.team.currentEmployees,
            activeTeam: state.team.selectedTeamData.partnerCode,
            hr: state.team.selectedTeamData.hr,
            email: state.auth.user.email,
            styles: state.styles,
            // activeTeam: state.auth.user.activeTeam,
        }),
        shallowEqual
    )
    // const [columns, setColumns] = useState<any[]>([])
    const navigate = useNavigate()

    const removeMember = async (id: string) => {
        const members = [...currentEmployees]
        const found = members.find((member) => member.id === id)
        if (!found || !found.partnerCode) return
        // eslint-disable-next-line no-console
        // console.log(found)

        await removeTeamMember(id, 'resigned')
        message.success('Successfully resigned employee from payroll.')
    }

    const [status, setStatus] = useState('active')
    const [form] = ProForm.useForm()
    const [toSearch, setToSearch] = useState('')

    // const cachedFilters = localStorage.getItem('personnelFilters')
    // const filters = cachedFilters ? JSON.parse(cachedFilters) : []
    const [currentFilters, setCurrentFilters] = useState([])
    // eslint-disable-next-line no-console
    // console.log(filters);
    const columns: any = [
        {
            title: 'Name',
            dataIndex: 'displayName',
            width: '50%',
            sorter: (a: Personnel, b: Personnel) =>
                a.lastName.localeCompare(b.lastName),

            defaultSortOrder: 'ascend',
            render: (text: string, row: Personnel) => {
                // eslint-disable-next-line no-console
                // console.log(row.idUrl)

                return <EmployeeAvatar employee={row} initials={false} />
            },
        },
        {
            title: 'Position',
            dataIndex: 'role',
            filters: positions.map((pos) => ({
                text: pos.label,
                value: pos.value,
            })),
            onFilter: (value: string, record: any) =>
                record.role.indexOf(value) === 0,
            sorter: (a: Personnel, b: Personnel) =>
                a.role.localeCompare(b.role),
            // specify the condition of filtering result
            // here is that finding the name started with `value`
            width: '50%',
            render: (text: string, ) => {
                const val = positions.find((pos) => pos.value === text)
                return <div>{val?.label || text}</div>
            },
            filteredValue: currentFilters,
        },
        {
            title: 'Actions',
            render: (data: TeamMember) => {
                return (
                    <Row wrap={false} gutter={[12, 12]}>
                        <Col>
                            <Tooltip title={'Employee Time In/Out'}>
                                <Button
                                    type={'link'}
                                    onClick={() => handleShow(data)}
                                    style={{ padding: 0 }}
                                >
                                    <FaUserClock style={{ fontSize: 20 }} />
                                </Button>
                            </Tooltip>
                        </Col>
                        <Col>
                            <Tooltip title={'Update personnel'}>
                                <Button
                                    type={'link'}
                                    onClick={() =>
                                        navigate(`${data.id}/update`, {
                                            state: data,
                                        })
                                    }
                                    style={{ padding: 0 }}
                                >
                                    <FaUserEdit style={{ fontSize: 20 }} />
                                </Button>
                            </Tooltip>
                        </Col>
                        <Col>
                            <Popconfirm
                                title={'Are you sure to remove this personnel?'}
                                okButtonProps={{
                                    style: {
                                        backgroundColor:
                                            'rgb(19, 121, 170, .9)',
                                        border: 'none',
                                        color: 'white',
                                    },
                                    // loading: isSaving,
                                }}
                                okText="Remove"
                                onConfirm={() => removeMember(data.id)}
                            >
                                <Button type="link" style={{ padding: 0 }}>
                                    <Tooltip title="Resign employee">
                                        <MinusCircleOutlined
                                            style={{
                                                fontSize: 16,
                                                color: 'red',
                                            }}
                                        />
                                    </Tooltip>
                                </Button>
                            </Popconfirm>
                            {/* <TeamRole
                                key={data.id}
                                text={data.role}
                                row={data}
                                onSuccess={onSuccessRoleChange}
                                removeMember={removeMember}
                            /> */}
                        </Col>
                    </Row>
                )
            },
        },
    ]

    const [filteredMembers, setFilteredMembers] =
        useState<any>(currentEmployees)

    const handleSearch = (e: string) => {
        setToSearch(e)
    }

    const [openTimeInOut, setOpenTimeInOut] = useState(false)
    const [currentEmployee, setCurrentEmployee] = useState<any>(undefined)
    const handleShow = async (data: any) => {
        if (!data) {
            message.destroy()
            message.error('Select an employee first and try again.', 5)
            return
        }
        if (data) {
            setCurrentEmployee(data)
            form.setFieldsValue({
                salary: data.salary,
            })
        }
        // const dateToday = moment().format('MM-DD-YYYY')
        // const attendanceQ = query(
        //     collection(db, PERSONNEL_ATTENDANCE_REPOSITORY),
        //     where('employeeId', '==', data?.id),
        //     where('date', '==', dateToday),
        //     where('partnerCode', '==', data.partnerCode)
        // )
        // const querySnapshot = await getDocs(attendanceQ)
        // if (!data.currentAttendanceId) {
        //     message.info('No existing attendance')
        // } else {
        //     // const attendance = querySnapshot.docs[0]
        //     // const attendanceData = attendance.data()
        //     // setExistingAttendance({
        //     //     ...attendanceData,
        //     //     id: attendance.id
        //     // })
        //     const date = moment.unix(data.timeInOfTheDay)

        //     form.setFieldValue('date', date)
        //     form.setFieldValue('timeIn', date)
        // }
        setOpenTimeInOut(true)
    }
    const handleCancel = () => {
        setOpenTimeInOut(false)
        form.resetFields()
    }
    const handleFinishV2 = async (data: any) => {
        const currentDate = moment(data.date, 'MMMM DD, YYYY')
        const timeIn = moment(data.timeIn, 'h:mmA')
            .set('M', currentDate.month())
            .set('D', currentDate.date())
        const timeOut = moment(data.timeOut, 'h:mmA')
            .set('M', currentDate.month())
            .set('D', currentDate.date())

        if (currentEmployee && !currentEmployee.salary) {
            message.destroy()
            message.error('Employee has no salary', 5)
        }
        const timeLogs: Attendance = {
            ...data,
            timeIn: timeIn.unix(),
            partnerCode: currentEmployee.partnerCode,
            employeeId: currentEmployee.id,
            timeOut: timeOut.unix(),
            status: 'done',
            date: currentDate.format('MMM DD, YYYY'),
            creation: moment().unix(),
            modified: moment().unix(),
            employeeRole: currentEmployee.role,
            salary: data.salary,
            incentives: data.incentives || 0,
            paymentStatus: 'pending',
            // start: '',
            // end: '',
            modifiedBy: email,
            isRemoved: false,
            overtimePay: data.overtimePay || 0,
            overtimeHours: data.overtimeHours || 0,
            driverStatus: 'pending',
            assistantStatus: 'pending',
            employeeStatus: 'pending',
        }
        await addDoc(collection(db, 'attendance'), timeLogs)

        message.destroy()
        message.success('Successfully clocked employee in', 5)
        handleCancel()
    }

    // const handleFinish = async (data: any) => {
    //     if (!currentEmployee.currentAttendanceId) {
    //         const currentDate = moment(data.date, 'MMMM DD, YYYY')
    //         const timeIn = moment(data.timeIn, 'h:mmA')
    //             .set('M', currentDate.month())
    //             .set('D', currentDate.date())
    //         if (currentEmployee && !currentEmployee.salary) {
    //             message.destroy()
    //             message.error('Employee has no salary', 5)
    //         }
    //         // if(currentEmployee && !currentEmployee.salary){
    //         //     message.error("Assistant has no salary")
    //         // }
    //         const timeLogs = {
    //             timeIn: timeIn.unix(),
    //             partnerCode: currentEmployee.partnerCode,
    //             rfid: currentEmployee.rfid || '',
    //             employeeId: currentEmployee.id,
    //             vehicleId: '',
    //             tripId: '',
    //             timeOut: 0,
    //             status: 'pending',
    //             date: currentDate.format('MM-DD-YYYY'),
    //             creation: moment().unix(),
    //             modified: moment().unix(),
    //             email,
    //             employeeRole: currentEmployee.role,
    //             salary: currentEmployee.salary,
    //             incentives: 0,
    //         }
    //         const resp = await addDoc(collection(db, 'attendance'), timeLogs)
    //         const ref = doc(db, PERSONNEL_REPOSITORY, currentEmployee.id)
    //         await updateDoc(ref, {
    //             timeInOfTheDay: timeIn.unix(),
    //             currentAttendanceId: resp.id,
    //         })
    //         message.destroy()
    //         message.success('Successfully clocked employee in', 5)
    //         handleCancel()
    //     } else {
    //         const currentDate = moment(data.date, 'MMMM DD, YYYY')
    //         const timeIn = moment(data.timeIn, 'h:mmA')
    //             .set('M', currentDate.month())
    //             .set('D', currentDate.date())
    //         const timeOut = moment(data.timeOut, 'h:mmA')
    //             .set('M', currentDate.month())
    //             .set('D', currentDate.date())
    //         const attendanceRef = doc(
    //             db,
    //             PERSONNEL_ATTENDANCE_REPOSITORY,
    //             currentEmployee.currentAttendanceId
    //         )
    //         // const timeOut = moment(
    //         //     data.date + ` ${data.timeOut}`,
    //         //     'MMMM DD, YYYY h:mmA'
    //         // )
    //         // eslint-disable-next-line no-unreachable
    //         await updateDoc(attendanceRef, {
    //             timeIn: timeIn.unix(),
    //             timeOut: timeOut.unix(),
    //             modifiedBy: email,
    //             modified: moment().unix(),
    //         })
    //         const ref = doc(db, PERSONNEL_REPOSITORY, currentEmployee.id)
    //         await updateDoc(ref, {
    //             timeOutOfTheDay: timeOut.unix(),
    //             modifiedBy: email,
    //             modified: moment().unix(),
    //             timeInOfTheDay: 0,
    //             currentAttendanceId: '',
    //         })
    //         message.destroy()
    //         message.success('Successfully clocked employee out', 5)
    //         handleCancel()
    //     }
    // }

    // eslint-disable-next-line no-unused-vars
    const employeeTimeIn = async () => {
        // empRfid2 = 123223232, empRfid3 = 32323223, 4 = 35225856
        const employeeRfid = '2321cad23e2'
        const currentDate = moment('08-01-2023', 'MM-DD-YYYY')
        // const partnerCode = 'four'

        // Query the shifts collection for relevant shift data
        const q = query(
            collection(db, PERSONNEL_REPOSITORY),
            where('rfid', '==', employeeRfid)
        )
        const querySnapshot = await getDocs(q)
        const ret = querySnapshot.docs.map(async (docSnap) => {
            const employeeData = docSnap.data()
            if (employeeData.timeIn) {
                // const releaseDate = calculateReleaseDate(employeeData.timeIn)
                const timeLogs = {
                    timeIn: employeeData.timeIn,
                    partnerCode: employeeData.partnerCode,
                    employeeRfid,
                    employeeId: docSnap.id,
                    timeOut: currentDate.add(8, 'hours').unix(),
                    status: 'pending',
                    date: currentDate.format('MM-DD-YYYY'),
                    creation: moment().unix(),
                }
                await addDoc(collection(db, 'attendance'), timeLogs)

                await updateDoc(docSnap.ref, {
                    timeIn: 0,
                })
            } else {
                await updateDoc(docSnap.ref, {
                    timeIn: currentDate.unix(),
                })
            }
        })
        await Promise.all(ret)
    }

    const onChangeValue = (values: any, vals: any) => {
        // const currentDate = moment().format('MM-DD-YYYY')

        const salary = currentEmployee.salary

        const overtimeHours = vals['overtimeHours'] || 0
        // const overtimePay = vals['overtimePay'] || 0
        const overtimePayEquation = 1.25

        const overtimePay =
            Math.round((salary / 8) * overtimePayEquation) * overtimeHours
        form.setFieldsValue({
            overtimePay,
        })
        if (vals.timeIn && vals.timeOut && vals.date) {
            const date = moment(vals.date, 'MMMM DD, YYYY')
            const timeIn = moment(vals.timeIn, 'h:mmA')
                .set('M', date.month())
                .set('D', date.date())
            const timeOut = moment(vals.timeOut, 'h:mmA')
                .set('M', date.month())
                .set('D', date.date())
            const resp = calculateWorkTime(
                timeIn.format('HH:mm'),
                timeOut.format('HH:mm'),
                hr
            )
            form.setFieldsValue({
                minutesLate: resp.minutesLate,
                hoursRendered: parseFloat(resp.hours.toFixed(2)),
            })
            // eslint-disable-next-line no-console
            // console.log(values, resp)
        }
    }
    useEffect(() => {
        let timeout: any
        if (toSearch) {
            timeout = setTimeout(() => {
                const filtered = [...currentEmployees].filter((teamMember) => {
                    const splittedTitle = (teamMember.displayName as string)
                        .toLowerCase()
                        .replace(/ /g, '')
                    const splittedEmail = (teamMember.email as string)
                        .toLowerCase()
                        .replace(/ /g, '')
                    return (
                        splittedTitle.includes(toSearch.toLowerCase()) ||
                        splittedEmail.includes(toSearch.toLowerCase())
                    )
                })
                setFilteredMembers(filtered)
            }, 100)
        } else {
            setFilteredMembers(currentEmployees)
        }
        return () => {
            timeout && clearTimeout(timeout)
        }
    }, [currentEmployees, toSearch, activeTeam])

    useEffect(() => {
        const filtered = currentEmployees.filter((val) => val.status === status)
        setFilteredMembers(filtered)
    }, [status, currentEmployees])
    return (
        <>
            <div className="page-layout">
                <ModalForm
                    form={form}
                    onFinishFailed={(err) => {
                        if (err.errorFields.length) {
                            const firstError = err.errorFields[0]
                            message.destroy()
                            message.error(firstError.errors)
                            form.scrollToField(firstError.name, {
                                behavior: 'smooth',
                                block: 'center',
                            })
                        }
                    }}
                    open={openTimeInOut}
                    onFinish={handleFinishV2}
                    modalProps={{
                        onCancel: handleCancel,
                        centered: true,
                    }}
                    initialValues={{
                        date: moment(),
                    }}
                    width={300}
                    onValuesChange={onChangeValue}
                >
                    <ProFormDatePicker
                        // disabled
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        name="date"
                        label={'Date'}
                        fieldProps={{
                            format: 'MMMM DD, YYYY',
                        }}
                    />
                    <ProFormTimePicker
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        name="timeIn"
                        label={'Time In'}
                        fieldProps={{
                            format: 'h:mmA',
                            use12Hours: true,
                        }}
                    />
                    <ProFormTimePicker
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        label={'Time Out'}
                        name="timeOut"
                        fieldProps={{
                            format: 'h:mmA',
                            use12Hours: true,
                        }}
                    />
                    {/* <ProFormDigit
                        label={'Hours Rendered'}
                        name="hoursRendered"
                    />
                    <ProFormDigit
                        label={'Minutes Late'}
                        name="minutesLate"
                    /> */}
                    <ProFormDigit label={'Salary'} name="salary" />
                    <ProFormDigit label={'Incentives'} name="incentives" />
                    <ProFormDigit
                        label={'Overtime Hours'}
                        name="overtimeHours"
                    />
                    <ProFormDigit label={'Overtime Pay'} name="overtimePay" />
                </ModalForm>
                <div
                    style={{
                        padding: '16px 0',
                    }}
                >
                    <Row
                        align={'middle'}
                        gutter={[
                            {
                                xs: 6,
                                md: 12,
                            },
                            {
                                xs: 6,
                                md: 12,
                            },
                        ]}
                        wrap={false}
                    >
                        <Col>
                            <Input
                                value={toSearch}
                                autoFocus
                                onChange={(e) => handleSearch(e.target.value)}
                                allowClear
                                placeholder={'Search name/email'}
                                suffix={
                                    <Button
                                        icon={<SearchOutlined />}
                                        type="link"
                                    />
                                }
                                // addonBefore={xs ? null : <>Search name</>}
                            />
                        </Col>
                        <Col>
                            <Dropdown
                                menu={{
                                    items: employeeStatus.map((status) => ({
                                        label: status.label,
                                        key: status.value,
                                    })),
                                    onClick: (selected) =>
                                        setStatus(selected.key),
                                    activeKey: status,
                                }}
                                trigger={['click']}
                            >
                                <FilterFilled
                                    style={{
                                        color: styles.primary,
                                        fontSize: 24,
                                    }}
                                />
                            </Dropdown>
                        </Col>
                    </Row>
                </div>
                <Table
                    cardBordered
                    scroll={{ x: 800 }}
                    dataSource={filteredMembers}
                    pagination={{
                        showTotal(total, range) {
                            return (
                                <Typography.Text>
                                    <Typography.Text type="secondary">
                                        {range[0]} - {range[1]}
                                    </Typography.Text>
                                    {' of '}
                                    <Typography.Text
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        {total}
                                    </Typography.Text>
                                </Typography.Text>
                            )
                        },
                    }}
                    style={{
                        // padding: '0 12px 0',
                        borderRadius: 10,
                    }}
                    onChange={(pagination: any, filters: any) => {
                        // eslint-disable-next-line no-console
                        // console.log(filters);
                        localStorage.setItem(
                            'personnelFilters',
                            JSON.stringify(filters['role'])
                        )
                        setCurrentFilters(filters['role'])
                    }}
                    columns={columns}
                    toolBarRender={false}
                />
            </div>

            <br />
            <br />
            <br />
            <br />
        </>
    )
}

export default MyPersonnel
