import React from 'react';
import TweenOne from 'rc-tween-one';
// import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { Typography } from 'antd';
import QueueAnim from 'rc-queue-anim';
import RcScrollElement from 'rc-scroll-anim/lib/ScrollElement';

export default function Page2() {
  return (
    <RcScrollElement
      id="page2-landing"
      className="content-wrapper page"
    >
      <QueueAnim
        className="text-wrapper left-text"
        key="text"
        duration={450}
        type="bottom"
        leaveReverse
      >
        
        <Typography.Paragraph
            type={'secondary'}
            style={{
                fontSize: 24,
                marginBottom: 0
            }}
        >
            02
        </Typography.Paragraph>
        <br/>
        <h2 key="h2">Accessible</h2>
        <p key="p" style={{ maxWidth: 260 }}>Offline capability ensures the system remains operational, even in areas with deadspots, providing seamless service throughout your routes.</p>
        {/* <div key="button">
          <a>
            <Button type="primary" size="large">
              了解更多
              <Icon type="right" />
            </Button>
          </a>
        </div> */}
      </QueueAnim>
      <TweenOne
        key="image"
        className="image2 image-wrapper"
        animation={{ x: 0, opacity: 1, ease: 'easeOutQuad' }}
        style={{ transform: 'translateX(100px)', opacity: 0 }}
      />
    </RcScrollElement>
  );
}
