import { Row, Col } from 'antd'
import { withTranslation } from 'react-i18next'
import { SvgIcon } from '../../../common/SvgIcon'
import { Button } from '../../../common/Button'
import { ContentBlockProps } from '../types'
import { Fade } from 'react-awesome-reveal'
import {
    RightBlockContainer,
    Content,
    ContentWrapper,
    // ButtonWrapper,
} from './styles'

const RightBlock = ({
    title,
    content,
    button,
    icon,
    t,
    id,
}: ContentBlockProps) => {
    const scrollTo = (id: string) => {
        const element = document.getElementById(id) as HTMLDivElement
        element.scrollIntoView({
            behavior: 'smooth',
        })
    }
    return (
        <RightBlockContainer>
            <Fade direction="right">
                <Row justify="space-between" align="middle" id={id}>
                    <Col lg={11} md={11} sm={11} xs={24}>
                        <ContentWrapper>
                            <h6 style={{ fontWeight: 'bold' }}>{t(title)}</h6>
                            <Content>{t(content)}</Content>
                            <Row>
                                {typeof button === 'object' &&
                                    button.map((item: any, id: number) => {
                                        return (
                                            <Button
                                                key={id}
                                                color={item.color}
                                                fixedWidth={true}
                                                onClick={() =>
                                                    scrollTo(item.scroll)
                                                }
                                            >
                                                {t(item.title)}
                                            </Button>
                                        )
                                    })}
                            </Row>
                        </ContentWrapper>
                    </Col>
                    <Col lg={11} md={11} sm={12} xs={24}>
                        <SvgIcon src={icon} width="100%" height="100%" />
                    </Col>
                </Row>
            </Fade>
        </RightBlockContainer>
    )
}

export default withTranslation()(RightBlock)
