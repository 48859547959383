import {
    Row,
    Col,
    Typography,
    Segmented,
    Modal,
    Spin,
    // Image,
    Grid,
    Button,
    Table as AntTable,
    Statistic,
} from 'antd'
import { db } from '../../boot'
import { updateExpenseState } from '../../../app/redux/expense/expense.slice'
import { useAppDispatch, useAppSelector } from '../../../app/store'
import html2canvas from 'html2canvas'
import {
    EXPENSE_REPOSITORY,
    VEHICLE_REPOSITORY,
} from '../../../data/repositories/constants'
import {
    collection,
    query,
    where,
    orderBy,
    limit,
    onSnapshot,
    getDocs,
    getDoc,
    doc,
} from 'firebase/firestore'
import React, { useEffect, useRef, useState } from 'react'
import { shallowEqual } from 'react-redux'
import moment from 'moment'
import { formatNumberWithCommas } from '../../../app/components/utils'
import { Trip, Vehicle } from '../../../domain/entities/Transport'
// import { capitalize } from '../utils'
import jsPDF from 'jspdf'
import { ProFormDateRangePicker, WaterMark } from '@ant-design/pro-components'
import { FaPrint } from 'react-icons/fa'
import Table from '../../../app/components/Table'
export function calculateDates(monthsBehind: number) {
    const currentDate = moment()
    const targetDate = moment(currentDate).subtract(monthsBehind, 'months')

    const firstDate = moment(targetDate).startOf('month')
    const lastDate = moment(targetDate).endOf('month')

    return {
        start: firstDate.unix(),
        end: lastDate.unix(),
    }
}

interface PersonnelBreakdown {
    salary: number
    incentives: number
    title: string
    allowance: number
    overtimeHours: number
    overtimePay: number
    totalDays: number
    rate: number
}

interface GasBreakdown {
    date: number
    title: string
    amount: number
    volume: number
    start: string
    end: string
}
function FinancialReport() {
    const { activeTeam, teamData } = useAppSelector(
        (state) => ({
            styles: state.styles,
            // totalExpenses:
            teamData: state.team.selectedTeamData,
            //     totalCollection:
            //         state.team.selectedTeamData?.financialPerformance?.totalCollection || 0,
            // expenses: state.expense.expenses,
            activeTeam: state.auth.user.activeTeam,
        }),
        shallowEqual
    )
    // eslint-disable-next-line no-console
    // console.log(activeTeam)
    // const cardPercent = teamData.cardPercentage || 2
    // const cardRatio = cardPercent / 100
    const [currentFilter, setCurrentFilter] = useState('today')
    const [loading, setLoading] = useState(false)
    const [reports, setReports] = useState<{
        revenue: any[]
        expense: any[]
        start: number
        end: number
        totalExpense: number
        totalCollection: number
        expenseBreakdown: any
        revenueBreakdown: any
        expenseReport: any
        collectionReport: any
    }>({
        revenue: [],
        expense: [],
        start: 0,
        end: 0,
        totalExpense: 0,
        totalCollection: 0,
        expenseBreakdown: [],
        revenueBreakdown: [],
        expenseReport: {},
        collectionReport: {},
    })
    const [overallExpense, setOverallExpense] = useState(0)
    const [overallCollection, setOverallCollection] = useState(0)
    const [overallCashReceived, setOverallCashReceived] = useState(0)
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (!activeTeam) return
        // dispatch(getTeamSavings(activeTeam))
        const ref = collection(db, EXPENSE_REPOSITORY)
        const q = query(
            ref,
            where('partnerCode', '==', activeTeam),
            where('isRemoved', '==', false),
            orderBy('creation', 'desc'),
            limit(10)
        )
        const unsub = onSnapshot(q, (doc) => {
            const data = doc.docs.map((docSnap) => ({
                ...docSnap.data(),
                id: docSnap.id,
            }))

            dispatch(
                updateExpenseState({
                    name: 'expenses',
                    value: data,
                })
            )
        })

        return () => unsub()
    }, [activeTeam])
    const onSelect = async (data: any) => {
        setCurrentFilter(data)
    }
    function getStartOfMonthUnix(formattedDate: string) {
        const startDate = moment(formattedDate, 'MMMM YYYY').startOf('month')
        return startDate.unix()
    }
    // const [isReady, setIsReady] = useState(false)
    const mainQuery = async (data?: [number, number]) => {
        setLoading(true)
        try {
            const dates = calculateDates(1)
            const endOfDay = data
                ? data[1]
                : currentFilter === 'lastMonth'
                ? dates.end
                : moment().endOf('day').unix() // Today

            const startOfDay = data
                ? data[0]
                : currentFilter === 'today'
                ? moment().startOf('day').unix()
                : currentFilter === 'week'
                ? moment().subtract(7, 'days').startOf('day').unix()
                : currentFilter === 'month'
                ? getStartOfMonthUnix(moment().format('MMMM YYYY'))
                : currentFilter === 'lastMonth'
                ? dates.start
                : moment().subtract(31, 'days').startOf('day').unix()

            const ref = collection(db, 'expense')

            const q = query(
                ref,
                where('requestDate', '>=', startOfDay),
                where('requestDate', '<=', endOfDay),
                where('partnerCode', '==', activeTeam)
            )

            // const categories: string[] = []
            const snapshot = await getDocs(q)
            // eslint-disable-next-line no-console

            const financialReport: any = {
                totalExpense: 0,
                totalCollection: 0,
            }
            const expenseReport: any = {}
            const collectionReport: any = {}
            const expenseBreakdown: any = {}
            const revenueBreakdown: any = {}
            // let totalLess = 0
            snapshot.docs.forEach((doc) => {
                const data = doc.data()
                // if(!categories.includes(data.category)) categories.push(data.category)
                // const monthly = moment.unix(data.requestDate).format('MMM-YYYY')
                // eslint-disable-next-line no-console
                // console.log(data);

                const name =
                    data.type === 'payable' ? 'totalExpense' : 'totalCollection'
                let amount = data.amount

                if (
                    data.category.toLowerCase() === 'fare revenue' &&
                    data.purpose.includes('without')
                ) {
                    // const less = data.amount * cardRatio
                    // const newAmount = data.amount - less
                    // amount = newAmount
                    // eslint-disable-next-line no-console
                    // console.log(data.amount, amount, less)
                    // if (!expenseReport['Card Revenue Expense']) {
                    //     expenseReport['Card Revenue Expense'] = 0
                    // }
                    // expenseReport['Card Revenue Expense'] += less
                    // if (!expenseBreakdown['Card Revenue Expense']) {
                    //     expenseBreakdown['Card Revenue Expense'] = []
                    // }
                    // expenseBreakdown['Card Revenue Expense'].push({
                    //     ...data,
                    //     category: 'Card Revenue Expense',
                    //     amount: less,
                    //     purpose: `${cardPercent}% of ${data.amount} fare collection system's share`,
                    // })
                }
                if (data.type === 'payable') {
                    if (!expenseReport[data.category]) {
                        expenseReport[data.category] = 0
                    }
                    if (!expenseBreakdown[data.category]) {
                        expenseBreakdown[data.category] = []
                    }
                    expenseBreakdown[data.category].push(data)
                    expenseReport[data.category] += amount

                    financialReport[name] += amount
                } else {
                    if (!collectionReport[data.category]) {
                        collectionReport[data.category] = 0
                    }
                    collectionReport[data.category] += amount
                    financialReport[name] += amount

                    if (!revenueBreakdown[data.category]) {
                        revenueBreakdown[data.category] = []
                    }
                    revenueBreakdown[data.category].push(data)
                }
            })
            // collectionReport['totalCollection']
            // // eslint-disable-next-line no-console
            // console.log(collectionReport, expenseReport)
            setReports({
                revenue: Object.keys(collectionReport).map((key) => {
                    const mainData = collectionReport[key]
                    // const data = Object.keys(collectionReport[key])
                    // const values: any = []

                    // data.forEach((dataKey)=> {
                    //     values.push({label: dataKey, value: mainData[dataKey]})
                    // })
                    return {
                        label: key,
                        value: mainData,
                    }
                }),
                expense: Object.keys(expenseReport).map((key) => {
                    const mainData = expenseReport[key]
                    // const data = Object.keys(expenseReport[key])
                    // const values: any = []

                    // data.forEach((dataKey)=> {
                    //     values.push({label: dataKey, value: mainData[dataKey]})
                    // })
                    return {
                        label: key,
                        value: mainData,
                    }
                }),
                expenseBreakdown,
                revenueBreakdown,
                expenseReport,
                collectionReport,
                start: startOfDay,
                end: endOfDay,
                ...financialReport,
            })
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err)
        }
        setLoading(false)
    }
    useEffect(() => {
        if (!currentFilter) {
            if (!reports.start || !reports.end) return
            mainQuery([reports.start, reports.end])

            return
        }
        mainQuery()
    }, [currentFilter, activeTeam, reports.start, reports.end])

    const [costOfSales, setCostOfSales] = useState<{
        driverSalaries: number
        cardRevenueExpense: number
        assistantSalaries: number
        totalGastCost: number
        cardRevenue: number
        cashRevenue: number
        totalDriverOvertimeHours: number
        totalDriverOvertimePay: number
        totalAssistantOvertimeHours: number
        totalAssistantOvertimePay: number
        totalDriverIncentives: number
        totalAssistantIncentives: number
        totalAssistantDaysPresent: number
        totalDriverDaysPresent: number
        drivers: any[]
        assistants: any[]
        gas: any[]
        cards: any[]
        cashCollections: any[]
        cardsExpense: any[]
    }>({
        totalDriverOvertimeHours: 0,
        totalDriverOvertimePay: 0,
        totalAssistantOvertimeHours: 0,
        totalAssistantOvertimePay: 0,
        driverSalaries: 0,
        assistantSalaries: 0,
        totalGastCost: 0,
        drivers: [],
        assistants: [],
        gas: [],
        cards: [],
        cardRevenue: 0,
        cashRevenue: 0,
        cardRevenueExpense: 0,
        cashCollections: [],
        cardsExpense: [],
        totalDriverIncentives: 0,
        totalAssistantIncentives: 0,
        totalAssistantDaysPresent: 0,
        totalDriverDaysPresent: 0,
    })

    useEffect(() => {
        // if(loading) return
        let end = reports?.end || moment().endOf('day').unix()
        let start = reports?.start || moment().startOf('day').unix()
        const main = async () => {
            try {
                const endOfDay = end
                const startOfDay = start
                const ref = collection(db, 'trips')
                let driverSalaries = 0
                let totalAssistantDaysPresent = 0
                let totalDriverDaysPresent = 0
                let totalDriverOvertimeHours = 0
                let totalDriverOvertimePay = 0
                let totalAssistantOvertimeHours = 0
                let totalAssistantOvertimePay = 0
                let assistantSalaries = 0
                let totalGastCost = 0
                let cardRevenue = 0
                let cardRevenueExpense = 0
                let cashRevenue = 0
                let totalCashReceived = 0
                let totalDriverIncentives = 0
                let totalAssistantIncentives = 0
                const q = query(
                    ref,
                    where('shiftStartTime', '>=', startOfDay),
                    where('shiftStartTime', '<=', endOfDay),
                    where('partnerCode', '==', activeTeam),
                    // where('tallied', '==', true),
                    where('isRemoved', '==', false),
                    where('status', '==', 'done'),
                    where('paymentStatus', '==', 'pending'),
                    limit(30)
                )
                const snapshot = await getDocs(q)
                // eslint-disable-next-line no-console
                // console.log(snapshot.size, startOfDay)
                // const drivers: any = []
                // const assistants: any = []
                const gas: any = []
                const cards: any = []
                const cardsExpense: any = []
                const cashCollections: any = []
                const cashDones: any = []
                const driversObj: any = {}
                const assistantsObj: any = {}
                const resp = snapshot.docs.map(async (dc) => {
                    const trip = dc.data() as Trip
                    // eslint-disable-next-line no-console
                    // console.log(trip);

                    const driverId = trip?.driver?.id || trip.driverId
                    const assistantId = trip?.assistant?.id || trip.assistantId
                    if (trip.totalGasCost) {
                        totalGastCost += trip.totalGasCost
                    }
                    cashRevenue += trip.cashCollected

                    const driverSalary = trip.driverSalary || 0
                    const driverName =
                        trip?.driver?.displayName ||
                        trip?.driver?.firstName ||
                        ''
                    const driverOvertimePay = trip.driverOvertimePay || 0
                    const driverOvertimeHours = trip.driverOvertimeHours || 0
                    const driverIncentives = trip.driverIncentives || 0

                    totalCashReceived += trip?.totalCashReceived || 0
                    totalDriverOvertimeHours += driverOvertimeHours
                    totalDriverOvertimePay += driverOvertimePay
                    totalDriverIncentives += driverIncentives
                    // eslint-disable-next-line no-console
                    // console.log(trip.totalCashReceived)
                    if (!driversObj[driverId]) {
                        driversObj[driverId] = {
                            title: driverName,
                            overtimePay: driverOvertimePay,
                            overtimeHours: driverOvertimeHours,
                            incentives: driverIncentives,
                            rate: driverSalary,
                            salary: driverSalary,
                            totalDays: 1,
                            data: [],
                        }
                    }
                    totalAssistantDaysPresent += 1
                    totalDriverDaysPresent += 1
                    driversObj[driverId]['totalDays'] += 1
                    driversObj[driverId]['salary'] += driverSalary
                    driversObj[driverId]['overtimePay'] += driverOvertimePay
                    driversObj[driverId]['overtimeHours'] += driverOvertimeHours
                    driversObj[driverId]['incentives'] += driverIncentives
                    driversObj[driverId]['data'].push({
                        title: driverName,
                        salary: driverSalary,
                        overtimePay: driverOvertimePay,
                        overtimeHours: driverOvertimeHours,
                        incentives: driverIncentives,
                        rate: driverSalary,
                    })
                    // -----------------------

                    if (assistantId) {
                        const assistantSalary = trip.assistantSalary || 0
                        const assistantName =
                            trip?.assistant?.displayName ||
                            trip?.assistant?.firstName ||
                            ''

                        const assistantOvertimePay =
                            trip.assistantOvertimePay || 0
                        const assistantOvertimeHours =
                            trip.assistantOvertimeHours || 0
                        const assistantIncentives =
                            trip.assistantIncentives || 0
                        totalAssistantOvertimeHours += assistantOvertimeHours
                        totalAssistantOvertimePay += assistantOvertimePay
                        totalAssistantIncentives += assistantIncentives
                        if (!assistantsObj[assistantId]) {
                            assistantsObj[assistantId] = {
                                title: assistantName,
                                overtimePay: assistantOvertimePay,
                                overtimeHours: assistantOvertimeHours,
                                incentives: assistantIncentives,
                                rate: assistantSalary,
                                totalDays: 1,
                                salary: assistantSalary,
                                data: [],
                            }
                        }
                        assistantsObj[assistantId as string]['totalDays'] += 1
                        assistantsObj[assistantId]['salary'] += assistantSalary

                        assistantsObj[assistantId as string]['overtimePay'] +=
                            assistantOvertimePay
                        assistantsObj[assistantId as string]['overtimeHours'] +=
                            assistantOvertimeHours
                        assistantsObj[assistantId as string]['incentives'] +=
                            assistantIncentives

                        assistantsObj[assistantId]['data'].push({
                            salary: assistantSalary,
                            title: assistantName,
                            overtimePay: assistantOvertimePay,
                            overtimeHours: assistantOvertimeHours,
                            incentives: assistantIncentives,
                            rate: assistantSalary,
                        })

                        if (assistantSalary) {
                            assistantSalaries += assistantSalary
                        }
                    }
                    if (driverSalary) {
                        driverSalaries += driverSalary
                    }
                    const ref = doc(db, VEHICLE_REPOSITORY, trip.vehicleId)
                    const vehicleSnap = await getDoc(ref)
                    const vehicle = vehicleSnap.data() as Vehicle
                    // eslint-disable-next-line no-console
                    // console.log(vehicleSnap.exists(), `${vehicle.title}-${vehicle.routeName}`, trip)

                    if (vehicleSnap.exists()) {
                        gas.push({
                            date: moment
                                .unix(trip.shiftStartTime)
                                .format('MMM-D'),
                            title: `${vehicle.title}-${vehicle.routeName}`,
                            amount: trip.totalGasCost,
                            volume: trip.totalGasConsumed,
                            start: moment
                                .unix(trip.shiftStartTime)
                                .format('h:mmA'),
                            end: moment.unix(trip.shiftEndTime).format('h:mmA'),
                        })
                        // const less = trip.cardRevenue
                        cards.push({
                            date: trip.shiftStartTime,
                            requestDate: trip.shiftStartTime,
                            title: `${vehicle.title}-${vehicle.routeName}`,
                            amount: trip.cardRevenue,
                            category: 'Card Revenue',
                            // purpose: `Card Collection with less ${cardPercent}%`,
                            signatureUrl: trip?.signatureUrl || '',
                            total: 0,
                        })
                        // cardsExpense.push({
                        //     date: trip.shiftStartTime,
                        //     requestDate: trip.shiftStartTime,
                        //     title: `${vehicle.title}-${vehicle.routeName}`,
                        //     amount: less,
                        //     category: 'Card Revenue Expense',
                        //     purpose: `${cardPercent}% of ${trip.cardRevenue} fare collection system's  share`,
                        //     signatureUrl: trip?.signatureUrl || '',
                        //     total: 0,
                        // })
                        const id = `${trip.vehicleId}-${moment
                            .unix(trip.shiftStartTime)
                            .format('MM-DD-YYYY')}`
                        if (!cashDones.includes(id)) {
                            cashCollections.push({
                                date: trip.shiftStartTime,
                                requestDate: trip.shiftStartTime,
                                title: `${vehicle.title}-${vehicle.routeName}`,
                                amount: trip.cashCollected,
                                category: 'Cash Revenue',
                                purpose: `Cash remittance: ${trip.driver?.displayName} - ${trip.assistant?.displayName}`,
                                signatureUrl: trip?.signatureUrl || '',
                                total: 0,
                            })
                            cashDones.push(id)
                        }
                        // cardRevenueExpense += less
                        // const newAmount = trip.cardRevenue - less
                        cardRevenue += trip.cardRevenue
                    }
                    return true
                })

                await Promise.all(resp)
                const drivers = Object.keys(driversObj).map((key) => {
                    return driversObj[key]
                })
                const assistants = Object.keys(assistantsObj).map((key) => {
                    return assistantsObj[key]
                })

                // eslint-disable-next-line no-console
                console.log(drivers, driversObj, assistantsObj)
                setOverallCashReceived(totalCashReceived)
                setOverallExpense(
                    reports?.totalExpense ||
                        0 +
                            driverSalaries +
                            assistantSalaries +
                            totalGastCost +
                            cardRevenueExpense
                )
                // eslint-disable-next-line no-console
                // console.log(
                //     reports.totalCollection,
                //     cardRevenue,
                //     cashRevenue,
                //     drivers
                // )

                setOverallCollection(
                    reports.totalCollection + cardRevenue + cashRevenue
                )

                setCostOfSales({
                    driverSalaries,
                    assistantSalaries,
                    cardRevenueExpense,
                    totalGastCost,
                    drivers,
                    assistants,
                    gas,
                    cards,
                    cardsExpense,
                    cashCollections,
                    cardRevenue,
                    cashRevenue,
                    totalDriverOvertimeHours,
                    totalDriverOvertimePay,
                    totalAssistantOvertimeHours,
                    totalAssistantOvertimePay,
                    totalDriverIncentives,
                    totalAssistantIncentives,
                    totalAssistantDaysPresent,
                    totalDriverDaysPresent,
                })
            } catch (err) {
                if (err instanceof Error) {
                    // eslint-disable-next-line no-console
                    console.error(err.message)
                } else {
                    // eslint-disable-next-line no-console
                    console.error('An unknown error occurred.')
                }
            }
            // setLoading(false)
        }
        main()
    }, [
        reports?.start,
        reports?.end,
        activeTeam,
        reports?.totalExpense,
        reports.totalCollection,
        costOfSales.driverSalaries,
        costOfSales.assistantSalaries,
        costOfSales.totalAssistantIncentives,
        costOfSales.totalAssistantOvertimeHours,
        costOfSales.totalAssistantOvertimePay,
        costOfSales.totalDriverIncentives,
        costOfSales.totalDriverOvertimeHours,
        costOfSales.totalDriverOvertimePay,
        costOfSales.totalGastCost,
        // loading
    ])

    const cellStyle = {
        width: 300,
    }

    const [openBreakdown, setOpenbreakdown] = useState<any>({
        open: false,
        data: [],
        label: '',
        type: '',
        total: 0,
    })

    const [costOfSaleBreakdown, setCostOfSaleBreakdown] = useState<{
        open: boolean
        data: any[]
        label: string
        type: string
        total: number
        totalOvertimeHours: number
        totalOvertimePay: number
        totalIncentives: number
        totalDaysPresent: number
    }>({
        open: false,
        data: [],
        label: '',
        type: '',
        total: 0,
        totalOvertimeHours: 0,
        totalOvertimePay: 0,
        totalIncentives: 0,
        totalDaysPresent: 0,
        // totalAssistantOvertimeHours: 0,
        // totalAssistantOvertimePay: 0,
    })
    const [gasBreakdown, setGasBreakdown] = useState<any>({
        open: false,
        data: [],
        total: 0,
    })
    // const handleShowCostOfSaleBreakdown = (
    //     type: 'assistant' | 'driver' | 'all'
    // ) => {
    //     let personnel =
    //         type === 'driver' ? costOfSales.drivers : costOfSales.assistants

    //     let total =
    //         type === 'driver'
    //             ? costOfSales.driverSalaries
    //             : costOfSales.assistantSalaries
    //     let totalOvertimeHours =
    //         type === 'driver'
    //             ? costOfSales.totalDriverOvertimeHours
    //             : costOfSales.totalAssistantOvertimeHours
    //     let totalOvertimePay =
    //         type === 'driver'
    //             ? costOfSales.totalDriverOvertimePay
    //             : costOfSales.totalAssistantOvertimePay
    //     let totalIncentives =
    //         type === 'driver'
    //             ? costOfSales.totalDriverIncentives
    //             : costOfSales.totalDriverIncentives
    //     let totalDaysPresent =
    //         type === 'driver'
    //             ? costOfSales.totalDriverDaysPresent
    //             : costOfSales.totalAssistantDaysPresent
    //     if (type === 'all') {
    //         personnel = [...costOfSales.drivers, ...costOfSales.assistants]
    //         total = costOfSales.driverSalaries + costOfSales.assistantSalaries
    //         totalOvertimeHours =
    //             costOfSales.totalDriverOvertimeHours +
    //             costOfSales.totalAssistantOvertimeHours
    //         totalOvertimePay =
    //             costOfSales.totalDriverOvertimePay +
    //             costOfSales.totalAssistantOvertimePay
    //         totalIncentives =
    //             costOfSales.totalDriverIncentives +
    //             costOfSales.totalAssistantIncentives
    //         totalDaysPresent =
    //             costOfSales.totalDriverDaysPresent +
    //             costOfSales.totalAssistantDaysPresent
    //     }

    //     setCostOfSaleBreakdown({
    //         open: true,
    //         data: personnel,
    //         total,
    //         type,
    //         label: `${capitalize(type)} Salaries`,
    //         totalOvertimeHours,
    //         totalOvertimePay,
    //         totalIncentives,
    //         totalDaysPresent,
    //         // totalAssistantOvertimeHours: costOfSales.totalAssistantOvertimeHours,
    //         // totalAssistantOvertimePay: costOfSales.totalAssistantOvertimePay,
    //     })
    // }
    // const handleShowGas = () => {
    //     const gas = costOfSales.gas
    //     const total = costOfSales.totalGastCost
    //     setGasBreakdown({
    //         open: true,
    //         data: gas,
    //         total,
    //     })
    // }
    const handleCancelGas = () => {
        setGasBreakdown({
            open: false,
            data: [],
            total: 0,
        })
    }
    const handleCancelCostOfSaleBreakdown = () => {
        setCostOfSaleBreakdown({
            open: false,
            data: [],
            label: '',
            type: '',
            total: 0,
            totalOvertimeHours: 0,
            totalOvertimePay: 0,
            totalIncentives: 0,
            totalDaysPresent: 0,
        })
    }
    // const handleShowBreakdown = (
    //     label: string,
    //     type: 'expense' | 'revenue',
    //     data?: any[],
    //     total?: number
    // ) => {
    //     const obj =
    //         type === 'expense'
    //             ? reports.expenseBreakdown
    //             : reports.revenueBreakdown
    //     const expObj =
    //         type === 'expense'
    //             ? reports.expenseReport
    //             : reports.collectionReport

    //     if (data && total) {
    //         setOpenbreakdown({
    //             open: true,
    //             data,
    //             label,
    //             type,
    //             total,
    //         })
    //         return
    //     }
    //     setOpenbreakdown({
    //         open: true,
    //         data: obj[label] ? obj[label] : [],
    //         label,
    //         type,
    //         total: expObj[label],
    //     })
    // }

    const handleCancelBreakdown = () => {
        setOpenbreakdown({
            open: false,
            data: [],
            label: '',
            type: '',
            total: 0,
        })
    }
    // // eslint-disable-next-line no-console
    // console.log(costOfSales)
    // eslint-disable-next-line no-console
    // console.log(openBreakdown, costOfSales, reports.expenseBreakdown)
    const { xs } = Grid.useBreakpoint()

    const handlePrint = () => {
        const input = document.getElementById('divToPrint')
        if (!input) return
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png')
            const pdf = new jsPDF()
            pdf.addImage(imgData, 'JPEG', 0, 0, 0, 0)
            // pdf.output('dataurlnewwindow');
            pdf.save(
                `${teamData.name}-income statement-${moment().format()}.pdf`
            )
        })
    }

    useEffect(() => {
        return () => {
            handleCancelBreakdown()
            handleCancelCostOfSaleBreakdown()
            handleCancelGas()
            setReports({
                revenue: [],
                expense: [],
                start: 0,
                end: 0,
                totalExpense: 0,
                totalCollection: 0,
                expenseBreakdown: [],
                revenueBreakdown: [],
                expenseReport: {},
                collectionReport: {},
            })
        }
    }, [])

    const [open, setOpen] = useState(false)
    const ref = useRef(null)
    return (
        <div className="page-layout">
            <Row>
                <Col>
                    <ProFormDateRangePicker
                        fieldProps={{
                            ref: ref,
                            open: open,
                            onOpenChange: (val) => setOpen(val),
                            onChange: (vals, ) => {
                                // eslint-disable-next-line no-console
                                // console.log(vals, format, ref.current)
                                if (!vals) {
                                    return
                                }
                                setReports({
                                    ...reports,
                                    start: vals[0]?.startOf('day').unix() || 0,
                                    end: vals[1]?.endOf('day').unix() || 0,
                                })
                                setCurrentFilter('')
                                setOpen(false)
                                if (ref.current) {
                                    // @ts-ignore
                                    ref.current.blur()
                                }
                            },
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={24} lg={12}>
                    <div style={{ overflow: xs ? 'scroll' : undefined }}>
                        <Segmented
                            value={currentFilter}
                            size="large"
                            onChange={onSelect}
                            options={[
                                {
                                    value: 'today',
                                    label: 'Today',
                                },
                                // {
                                //     value: 'week',
                                //     label: 'Last 7 days',
                                // },
                                {
                                    value: 'month',
                                    label: 'Current Month',
                                },
                                {
                                    value: 'lastMonth',
                                    label: 'Last Month',
                                },
                            ]}
                        />
                    </div>
                </Col>
                <Col xs={24} lg={12}>
                    {!xs && (
                        <Row justify={'end'}>
                            <Col>
                                <Button
                                    onClick={handlePrint}
                                    icon={
                                        <FaPrint style={{ marginRight: 5 }} />
                                    }
                                    type="primary"
                                    size="large"
                                    shape="round"
                                >
                                    Print to PDF
                                </Button>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
            <Modal
                centered
                footer={false}
                title={
                    <Typography.Title
                        level={3}
                        style={{ textTransform: 'capitalize' }}
                    >
                        {openBreakdown.label}
                    </Typography.Title>
                }
                open={openBreakdown.open}
                onCancel={handleCancelBreakdown}
                width={850}
            >
                <div
                    style={{
                        overflowX: xs ? 'scroll' : undefined,
                    }}
                >
                    <Table
                        toolBarRender={false}
                        dataSource={openBreakdown.data}
                        scroll={{ x: 800 }}
                        columns={[
                            {
                                title: 'Date',
                                dataIndex: 'requestDate',
                                render: (
                                    text: any
                                    // record: MemberReceivable,
                                    // index: number,
                                    // action: ProCoreActionType
                                ) => {
                                    const date = moment.unix(text)
                                    return `${date.format('MMMM DD')}`
                                },
                            },
                            {
                                title: 'Title',
                                dataIndex: 'title',
                            },
                            {
                                title: 'Amount',
                                dataIndex: 'amount',
                            },
                            {
                                title: 'Purpose',
                                dataIndex: 'purpose',
                            },
                            // {
                            //     title: 'Signature',
                            //     dataIndex: 'signatureUrl',
                            //     render: (text: any, data) => {
                            //         return (
                            //             <div>
                            //                 {data.signatureUrl ? (
                            //                     <Image
                            //                         src={data.signatureUrl}
                            //                         width={100}
                            //                     />
                            //                 ) : null}
                            //             </div>
                            //         )
                            //     },
                            // },
                        ]}
                        summary={() => {
                            return (
                                <>
                                    <AntTable.Summary.Row>
                                        <AntTable.Summary.Cell index={0}>
                                            <Typography.Text
                                                style={{ fontWeight: 'bolder' }}
                                            >
                                                Total
                                            </Typography.Text>
                                        </AntTable.Summary.Cell>
                                        <AntTable.Summary.Cell
                                            index={1}
                                        ></AntTable.Summary.Cell>
                                        <AntTable.Summary.Cell index={2}>
                                            <Statistic
                                                value={openBreakdown.total}
                                                precision={2}
                                                prefix="₱"
                                                valueStyle={{
                                                    fontWeight: 'bolder',
                                                }}
                                                prefixCls="₱"
                                            />
                                        </AntTable.Summary.Cell>
                                    </AntTable.Summary.Row>
                                    {/* <Table.Summary.Row>
                                  <Table.Summary.Cell index={0}>Balance</Table.Summary.Cell>
                                  <Table.Summary.Cell index={1} colSpan={2}>
                                    <Typography.Text type="danger">{totalBorrow - totalRepayment}</Typography.Text>
                                  </Table.Summary.Cell>
                                </Table.Summary.Row> */}
                                </>
                            )
                        }}
                    />
                </div>
            </Modal>
            <Modal
                footer={false}
                title={
                    <Typography.Title
                        level={3}
                        style={{ textTransform: 'capitalize' }}
                    >
                        {costOfSaleBreakdown.label}
                    </Typography.Title>
                }
                open={costOfSaleBreakdown.open}
                onCancel={handleCancelCostOfSaleBreakdown}
                width={800}
            >
                <div style={{ overflowX: 'scroll' }}>
                    <table>
                        <tr>
                            <th
                                style={{
                                    textAlign: 'left',
                                    textTransform: 'capitalize',
                                    width: 200,
                                }}
                            >
                                Name
                            </th>
                            <th style={{ textAlign: 'right', width: 100 }}>
                                Rate
                            </th>
                            <th style={{ textAlign: 'right', width: 100 }}>
                                Days Present
                            </th>
                            <th style={{ textAlign: 'right', width: 100 }}>
                                Regular Pay
                            </th>
                            <th style={{ textAlign: 'right', width: 100 }}>
                                OT Hours
                            </th>
                            <th style={{ textAlign: 'right', width: 100 }}>
                                OT Pay
                            </th>
                            <th style={{ textAlign: 'right', width: 100 }}>
                                Incentives
                            </th>
                            <th style={{ textAlign: 'right', width: 100 }}>
                                Total
                            </th>
                        </tr>
                        {costOfSaleBreakdown.data.map(
                            (emp: PersonnelBreakdown) => {
                                return (
                                    <tr>
                                        <td
                                            style={{
                                                textAlign: 'left',
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            {emp.title}
                                        </td>
                                        <td
                                            style={{
                                                textAlign: 'right',
                                            }}
                                        >
                                            {formatNumberWithCommas(
                                                emp.rate.toFixed(2)
                                            )}
                                        </td>
                                        <td
                                            style={{
                                                textAlign: 'right',
                                            }}
                                        >
                                            {emp.totalDays}
                                        </td>
                                        <td
                                            style={{
                                                textAlign: 'right',
                                            }}
                                        >
                                            {formatNumberWithCommas(
                                                emp.salary.toFixed(2)
                                            )}
                                        </td>
                                        <td
                                            style={{
                                                textAlign: 'right',
                                            }}
                                        >
                                            <Typography.Text
                                                style={{ fontWeight: 'normal' }}
                                            >
                                                {' '}
                                                {emp.overtimeHours}
                                            </Typography.Text>
                                        </td>
                                        <td
                                            style={{
                                                textAlign: 'right',
                                            }}
                                        >
                                            <Typography.Text
                                                style={{ fontWeight: 'normal' }}
                                            >
                                                {' '}
                                                {formatNumberWithCommas(
                                                    emp.overtimePay.toFixed(2)
                                                )}
                                            </Typography.Text>
                                        </td>
                                        <td
                                            style={{
                                                textAlign: 'right',
                                            }}
                                        >
                                            {formatNumberWithCommas(
                                                emp.incentives.toFixed(2)
                                            )}
                                        </td>

                                        <td
                                            style={{
                                                textAlign: 'right',
                                            }}
                                        >
                                            {formatNumberWithCommas(
                                                (
                                                    emp.incentives +
                                                    emp.salary +
                                                    emp.overtimePay
                                                ) // emp.allowance + // emp.incentives +
                                                    .toFixed(2)
                                            )}
                                        </td>
                                    </tr>
                                )
                            }
                        )}
                        <tr>
                            <th style={{ textAlign: 'left' }}></th>
                            <th></th>
                            <th>
                                <hr />
                            </th>
                            <th>
                                <hr />
                            </th>

                            <th style={{ textAlign: 'right' }}>
                                <hr />
                            </th>
                            <th style={{ textAlign: 'right' }}>
                                <hr />
                            </th>
                            <th style={{ textAlign: 'right' }}>
                                <hr />
                            </th>
                            <th style={{ textAlign: 'right' }}>
                                <hr />
                            </th>
                        </tr>
                        <tr>
                            <th style={{ textAlign: 'left' }}>Total</th>
                            <th></th>
                            <th style={{ textAlign: 'right' }}>
                                {costOfSaleBreakdown.totalDaysPresent}
                            </th>
                            <th>
                                ₱
                                {formatNumberWithCommas(
                                    (costOfSaleBreakdown.total || 0).toFixed(2)
                                )}
                            </th>
                            <th style={{ textAlign: 'right' }}>
                                {costOfSaleBreakdown.totalOvertimeHours}
                            </th>
                            <th style={{ textAlign: 'right' }}>
                                ₱
                                {formatNumberWithCommas(
                                    costOfSaleBreakdown.totalOvertimePay.toFixed(
                                        2
                                    )
                                )}
                            </th>
                            <th style={{ textAlign: 'right' }}>
                                ₱
                                {formatNumberWithCommas(
                                    costOfSaleBreakdown.totalIncentives.toFixed(
                                        2
                                    )
                                )}
                            </th>
                            <th style={{ textAlign: 'right' }}>
                                ₱
                                {formatNumberWithCommas(
                                    costOfSaleBreakdown.total.toFixed(2)
                                )}
                            </th>
                        </tr>
                    </table>
                </div>
            </Modal>
            <Modal
                footer={false}
                title={
                    <Typography.Title
                        level={4}
                        style={{ textTransform: 'capitalize' }}
                    >
                        Gasoline
                    </Typography.Title>
                }
                open={gasBreakdown.open}
                onCancel={handleCancelGas}
            >
                <div style={{ overflowX: xs ? 'scroll' : undefined }}>
                    <table style={{ width: xs ? 500 : undefined }}>
                        <tr>
                            <th
                                style={{
                                    textAlign: 'center',
                                    textTransform: 'capitalize',
                                    width: 70,
                                }}
                            >
                                Date
                            </th>
                            <th style={{ textAlign: 'center' }}>Vehicle</th>
                            <th style={{ textAlign: 'center' }}>Amount</th>
                            <th style={{ textAlign: 'center' }}>In Liters</th>
                            <th style={{ textAlign: 'center' }}>Departure</th>
                            <th style={{ textAlign: 'center' }}>Arrival</th>
                        </tr>
                        {gasBreakdown.data.map((emp: GasBreakdown) => {
                            return (
                                <tr>
                                    <td
                                        style={{
                                            textAlign: 'center',
                                            textTransform: 'capitalize',
                                            width: 50,
                                        }}
                                    >
                                        {emp.date}
                                    </td>
                                    <td
                                        style={{
                                            textAlign: 'center',
                                            width: 150,
                                        }}
                                    >
                                        {emp.title}
                                    </td>
                                    <td style={{ textAlign: 'right' }}>
                                        <Typography.Text
                                            style={{ fontWeight: 'normal' }}
                                        >
                                            {' '}
                                            {formatNumberWithCommas(
                                                emp.amount.toFixed(2)
                                            )}
                                        </Typography.Text>
                                    </td>
                                    <td
                                        style={{
                                            textAlign: 'center',
                                            padding: 6,
                                        }}
                                    >
                                        {emp.volume}
                                    </td>
                                    <td
                                        style={{
                                            textAlign: 'center',
                                            padding: 6,
                                        }}
                                    >
                                        {emp.start}
                                    </td>
                                    <td
                                        style={{
                                            textAlign: 'center',
                                            padding: 6,
                                        }}
                                    >
                                        {emp.end}
                                    </td>
                                </tr>
                            )
                        })}
                        <tr>
                            <th></th>
                            <th></th>
                            <th>
                                <hr />
                            </th>
                            <th></th>
                        </tr>
                        <tr>
                            <th>Total</th>
                            <th></th>
                            <th>
                                ₱
                                {formatNumberWithCommas(
                                    (costOfSales?.totalGastCost || 0).toFixed(2)
                                )}
                            </th>
                            <th></th>
                        </tr>
                    </table>
                </div>
            </Modal>

            <Row justify={'center'}>
                <Col>
                    <div
                        id="divToPrint"
                        style={
                            xs
                                ? {}
                                : {
                                      backgroundColor: '#fff',
                                      width: '210mm',
                                      minHeight: '297mm',
                                      padding: '24px',
                                  }
                        }
                    >
                        <WaterMark
                            image="/logo192.png"
                            markStyle={{
                                opacity: 0.1,
                            }}
                            content={[
                                `Perati-${teamData?.name?.toUpperCase()}`,
                                `${teamData?.name?.toUpperCase()}-2023`,
                            ]}
                            style={{ height: '297mm' }}
                        >
                            {xs ? (
                                <Typography.Paragraph
                                    style={{
                                        textAlign: 'center',
                                        fontSize: 22,
                                        marginBottom: 0,
                                    }}
                                >
                                    {teamData.headline}
                                </Typography.Paragraph>
                            ) : (
                                <Typography.Title
                                    style={{
                                        textAlign: 'center',
                                        marginBottom: 0,
                                    }}
                                >
                                    {teamData.headline}
                                </Typography.Title>
                            )}
                            {xs ? (
                                <Typography.Paragraph
                                    style={{
                                        textAlign: 'center',
                                        fontSize: 18,
                                        marginBottom: 0,
                                    }}
                                >
                                    {teamData.name}
                                </Typography.Paragraph>
                            ) : (
                                <Typography.Title
                                    style={{
                                        textAlign: 'center',
                                        margin: 0,
                                    }}
                                    level={5}
                                >
                                    ({teamData.name})
                                </Typography.Title>
                            )}
                            <Typography.Paragraph
                                style={{ textAlign: 'center' }}
                            >
                                Income statement
                            </Typography.Paragraph>
                            {currentFilter === 'today' ? (
                                <Typography.Paragraph
                                    style={{ textAlign: 'center' }}
                                >
                                    <Typography.Text
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        {moment
                                            .unix(reports.start)
                                            .format('MMM DD, YYYY')}
                                    </Typography.Text>
                                </Typography.Paragraph>
                            ) : (
                                <Typography.Paragraph
                                    style={{ textAlign: 'center' }}
                                >
                                    From{' '}
                                    <Typography.Text
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        {moment
                                            .unix(reports.start)
                                            .format('MMM DD')}
                                    </Typography.Text>{' '}
                                    to{' '}
                                    <Typography.Text
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        {moment
                                            .unix(reports.end)
                                            .format('MMM DD, ')}
                                    </Typography.Text>
                                    {moment.unix(reports.end).format('YYYY')}
                                </Typography.Paragraph>
                            )}
                            {loading ? (
                                <Spin />
                            ) : (
                                <Row gutter={[12, 24]}>
                                    <Col xs={24} lg={8}>
                                        <table>
                                            <tr>
                                                <th
                                                    style={{
                                                        textAlign: 'left',
                                                        ...cellStyle,
                                                        textTransform:
                                                            'capitalize',
                                                    }}
                                                >
                                                    Revenues
                                                </th>
                                                <th
                                                    style={{
                                                        textAlign: 'right',
                                                        width: 100,
                                                    }}
                                                ></th>
                                            </tr>
                                            {reports.revenue.map(
                                                (revenue: any) => {
                                                    return (
                                                        <tr>
                                                            <td
                                                                className=""
                                                                // onClick={() =>
                                                                //     handleShowBreakdown(
                                                                //         revenue.label,
                                                                //         'revenue'
                                                                //     )
                                                                // }
                                                                style={{
                                                                    textAlign:
                                                                        'left',
                                                                    ...cellStyle,
                                                                    textTransform:
                                                                        'capitalize',
                                                                    // cursor: 'pointer',
                                                                }}
                                                            >
                                                                {/* <Typography.Link> */}
                                                                {revenue.label}
                                                                {/* </Typography.Link> */}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    textAlign:
                                                                        'right',
                                                                }}
                                                            >
                                                                <Typography.Text
                                                                    style={{
                                                                        fontWeight:
                                                                            'normal',
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    {formatNumberWithCommas(
                                                                        revenue.value.toFixed(
                                                                            2
                                                                        )
                                                                    )}
                                                                </Typography.Text>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            )}
                                            {!reports.expenseBreakdown[
                                                'fare revenue'
                                            ] &&
                                                !reports.collectionReport[
                                                    'fare revenue'
                                                ] && (
                                                    <tr>
                                                        <td
                                                        // onClick={() =>
                                                        //     handleShowBreakdown(
                                                        //         `Fare Revenue(cash): ${moment
                                                        //             .unix(
                                                        //                 reports.start
                                                        //             )
                                                        //             .format(
                                                        //                 'MMMM DD'
                                                        //             )} to ${moment
                                                        //             .unix(
                                                        //                 reports.end
                                                        //             )
                                                        //             .format(
                                                        //                 'MMMM DD, YYYY'
                                                        //             )}`,
                                                        //         'revenue',
                                                        //         costOfSales.cashCollections,
                                                        //         costOfSales.cashRevenue
                                                        //     )
                                                        // }
                                                        >
                                                            {/* <Typography.Link> */}
                                                            Fare Revenue(cash)
                                                            {/* </Typography.Link> */}
                                                        </td>
                                                        <td
                                                            style={{
                                                                textAlign:
                                                                    'right',
                                                            }}
                                                        >
                                                            {formatNumberWithCommas(
                                                                costOfSales.cashRevenue.toFixed(
                                                                    2
                                                                )
                                                            )}{' '}
                                                        </td>
                                                    </tr>
                                                )}
                                            {!reports.expenseBreakdown[
                                                'fare revenue'
                                            ] &&
                                                !reports.collectionReport[
                                                    'fare revenue'
                                                ] && (
                                                    <tr>
                                                        <td
                                                        // onClick={() =>
                                                        //     handleShowBreakdown(
                                                        //         `Fare Revenue(card): ${moment
                                                        //             .unix(
                                                        //                 reports.start
                                                        //             )
                                                        //             .format(
                                                        //                 'MMMM DD'
                                                        //             )} to ${moment
                                                        //             .unix(
                                                        //                 reports.end
                                                        //             )
                                                        //             .format(
                                                        //                 'MMMM DD, YYYY'
                                                        //             )}`,
                                                        //         'revenue',
                                                        //         costOfSales.cards,
                                                        //         costOfSales.cardRevenue
                                                        //     )
                                                        // }
                                                        >
                                                            {/* <Typography.Link> */}
                                                            Fare Revenue(card)
                                                            {/* </Typography.Link> */}
                                                        </td>
                                                        <td
                                                            style={{
                                                                textAlign:
                                                                    'right',
                                                            }}
                                                        >
                                                            {formatNumberWithCommas(
                                                                costOfSales.cardRevenue.toFixed(
                                                                    2
                                                                )
                                                            )}{' '}
                                                        </td>
                                                    </tr>
                                                )}
                                            <tr>
                                                <th
                                                    style={{
                                                        textAlign: 'left',
                                                        ...cellStyle,
                                                    }}
                                                ></th>
                                                <th
                                                    style={{
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    <hr />
                                                </th>
                                            </tr>
                                            <tr>
                                                <th
                                                    style={{
                                                        textAlign: 'left',
                                                        ...cellStyle,
                                                        fontWeight: 'normal',
                                                    }}
                                                >
                                                    Total Revenues
                                                </th>
                                                <th
                                                    style={{
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    ₱
                                                    {formatNumberWithCommas(
                                                        overallCollection.toFixed(
                                                            2
                                                        )
                                                    )}{' '}
                                                </th>
                                            </tr>
                                            <tr>
                                                <th
                                                    style={{
                                                        textAlign: 'left',
                                                        ...cellStyle,
                                                        fontWeight: 'normal',
                                                    }}
                                                >
                                                    Total Cash Received
                                                </th>
                                                <th
                                                    style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'normal',
                                                    }}
                                                >
                                                    ₱
                                                    {formatNumberWithCommas(
                                                        overallCashReceived.toFixed(
                                                            2
                                                        )
                                                    )}{' '}
                                                </th>
                                            </tr>
                                        </table>
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <table>
                                            <tr>
                                                <th
                                                    style={{
                                                        textAlign: 'left',
                                                        ...cellStyle,
                                                    }}
                                                >
                                                    Cost of Sales
                                                </th>
                                                <th
                                                    style={{
                                                        textAlign: 'right',
                                                        width: 100,
                                                    }}
                                                ></th>
                                            </tr>
                                            <tr>
                                                <td
                                                    style={{
                                                        textAlign: 'left',
                                                        ...cellStyle,
                                                        textTransform:
                                                            'capitalize',
                                                    }}
                                                >
                                                    {/* <Typography.Link
                                                        onClick={() =>
                                                            handleShowCostOfSaleBreakdown(
                                                                'all'
                                                            )
                                                        }
                                                    > */}
                                                    Salaries
                                                    {/* </Typography.Link> */}
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    {formatNumberWithCommas(
                                                        (
                                                            costOfSales?.driverSalaries ||
                                                            0 +
                                                                costOfSales?.assistantSalaries ||
                                                            0
                                                        ).toFixed(2)
                                                    )}
                                                </td>
                                            </tr>
                                            {/* <tr>
                                                <td
                                                    style={{
                                                        textAlign: 'left',
                                                        ...cellStyle,
                                                        textTransform:
                                                            'capitalize',
                                                    }}
                                                >
                                                    <Typography.Link
                                                        onClick={() =>
                                                            handleShowCostOfSaleBreakdown(
                                                                'driver'
                                                            )
                                                        }
                                                    >
                                                        Driver-Salaries
                                                    </Typography.Link>
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    {formatNumberWithCommas(
                                                        (
                                                            costOfSales?.driverSalaries ||
                                                            0
                                                        ).toFixed(2)
                                                    )}
                                                </td>
                                            </tr> */}
                                            {/* <tr>
                                                <td
                                                    style={{
                                                        textAlign: 'left',
                                                        ...cellStyle,
                                                        textTransform:
                                                            'capitalize',
                                                    }}
                                                >
                                                    <Typography.Link
                                                        onClick={() =>
                                                            handleShowCostOfSaleBreakdown(
                                                                'assistant'
                                                            )
                                                        }
                                                    >
                                                        Assistant-Salaries
                                                    </Typography.Link>
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    {formatNumberWithCommas(
                                                        (
                                                            costOfSales?.assistantSalaries ||
                                                            0
                                                        ).toFixed(2)
                                                    )}
                                                </td>
                                            </tr> */}
                                            <tr>
                                                <td
                                                    style={{
                                                        textAlign: 'left',
                                                        ...cellStyle,
                                                    }}
                                                >
                                                    {/* <Typography.Link
                                                        onClick={() =>
                                                            handleShowGas()
                                                        }
                                                    > */}
                                                    Gasoline
                                                    {/* </Typography.Link> */}
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    {formatNumberWithCommas(
                                                        (
                                                            costOfSales?.totalGastCost ||
                                                            0
                                                        ).toFixed(2)
                                                    )}
                                                </td>
                                            </tr>
                                            {/* <tr>
                                                <td
                                                    style={{
                                                        textAlign: 'left',
                                                        ...cellStyle,
                                                    }}
                                                >
                                                    <Typography.Link
                                                        onClick={() =>
                                                            handleShowBreakdown(
                                                                `${cardPercent}% Card Revenue Expense`,
                                                                'expense',
                                                                costOfSales.cards,
                                                                costOfSales.cardRevenueExpense
                                                            )
                                                        }
                                                    >
                                                        {cardPercent}% Card
                                                        Revenue Expense
                                                    </Typography.Link>
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    {formatNumberWithCommas(
                                                        (
                                                            costOfSales?.cardRevenueExpense ||
                                                            0
                                                        ).toFixed(2)
                                                    )}
                                                </td>
                                            </tr> */}
                                            {/* <tr>
                                    <td style={{
                                            textAlign: 'left',
                                            ...cellStyle,
                                            textTransform: 'capitalize',
                                        }}>
                                            
                                        <Typography.Link>
                                            DEPRECIATION EXPENSE-CA
                                        </Typography.Link>
                                        
                                    </td>
                                    <td style={{ textAlign: 'right' }}>5000</td>
                                </tr> */}
                                            <tr>
                                                <th
                                                    style={{
                                                        textAlign: 'left',
                                                        ...cellStyle,
                                                    }}
                                                ></th>
                                                <th
                                                    style={{
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    <hr />
                                                </th>
                                            </tr>
                                            <tr>
                                                <th
                                                    style={{
                                                        textAlign: 'left',
                                                        ...cellStyle,
                                                        fontWeight: 'normal',
                                                    }}
                                                >
                                                    Total Cost of Sales
                                                </th>
                                                <th
                                                    style={{
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    ₱
                                                    {formatNumberWithCommas(
                                                        overallExpense.toFixed(
                                                            2
                                                        )
                                                    )}{' '}
                                                </th>
                                            </tr>
                                            <br />
                                            <tr>
                                                <th
                                                    style={{
                                                        textAlign: 'left',
                                                        ...cellStyle,
                                                        fontWeight: 'normal',
                                                    }}
                                                >
                                                    Total Revenue
                                                </th>
                                                <th
                                                    style={{
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    ₱
                                                    {formatNumberWithCommas(
                                                        overallCollection.toFixed(
                                                            2
                                                        )
                                                    )}
                                                </th>
                                            </tr>
                                            <tr>
                                                <th
                                                    style={{
                                                        textAlign: 'left',
                                                        ...cellStyle,
                                                        fontWeight: 'normal',
                                                    }}
                                                >
                                                    Total Expenses
                                                </th>
                                                <th
                                                    style={{
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    ₱
                                                    {formatNumberWithCommas(
                                                        overallExpense.toFixed(
                                                            2
                                                        )
                                                    )}
                                                </th>
                                            </tr>
                                            <tr>
                                                <th
                                                    style={{
                                                        textAlign: 'left',
                                                        ...cellStyle,
                                                    }}
                                                ></th>
                                                <th
                                                    style={{
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    <hr />
                                                </th>
                                            </tr>
                                            <tr>
                                                <th
                                                    style={{
                                                        textAlign: 'left',
                                                        ...cellStyle,
                                                    }}
                                                >
                                                    Net/Loss
                                                </th>
                                                <th
                                                    style={{
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    ₱
                                                    {formatNumberWithCommas(
                                                        (
                                                            overallCollection -
                                                            overallExpense
                                                        ).toFixed(2)
                                                    )}
                                                </th>
                                            </tr>
                                        </table>
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <table>
                                            <tr>
                                                <th
                                                    style={{
                                                        textAlign: 'left',
                                                        ...cellStyle,
                                                        textTransform:
                                                            'capitalize',
                                                        width: 200,
                                                    }}
                                                >
                                                    Expenses
                                                </th>
                                                <th
                                                    style={{
                                                        textAlign: 'right',
                                                        width: 150,
                                                    }}
                                                ></th>
                                            </tr>
                                            {reports.expense
                                                .sort((a: any, b: any) =>
                                                    a.label.localeCompare(
                                                        b.label
                                                    )
                                                )
                                                .map((expense: any) => {
                                                    return (
                                                        <tr>
                                                            <td
                                                                // onClick={() =>
                                                                //     handleShowBreakdown(
                                                                //         expense.label,
                                                                //         'expense'
                                                                //     )
                                                                // }
                                                                style={{
                                                                    textAlign:
                                                                        'left',
                                                                    ...cellStyle,
                                                                    textTransform:
                                                                        'capitalize',
                                                                    // cursor: 'pointer',
                                                                }}
                                                            >
                                                                {/* <Typography.Link> */}
                                                                {expense.label}
                                                                {/* </Typography.Link> */}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    textAlign:
                                                                        'right',
                                                                }}
                                                            >
                                                                <Typography.Text
                                                                    style={{
                                                                        fontWeight:
                                                                            'normal',
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    {formatNumberWithCommas(
                                                                        expense.value.toFixed(
                                                                            2
                                                                        )
                                                                    )}
                                                                </Typography.Text>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}

                                            {/* <tr>
                                    <th
                                        style={{ textAlign: 'left', ...cellStyle }}
                                    ></th>
                                    <th style={{ textAlign: 'right' }}>
                                        <hr />
                                    </th>
                                </tr> */}
                                            {/* <tr>
                                    <th style={{ textAlign: 'left', ...cellStyle, fontWeight: 'normal' }}>
                                        Total Expense
                                    </th>
                                    <th style={{ textAlign: 'right' }}>
                                        ₱
                                        {formatNumberWithCommas(
                                            reports.totalExpense.toFixed(2)
                                        )}{' '}
                                    </th>
                                </tr> */}
                                        </table>
                                    </Col>
                                </Row>
                            )}

                            <br />
                            {/* <Typography.Paragraph style={{marginLeft: -10, fontWeight: 'bold', marginBottom: 0}}>
            Expense
            </Typography.Paragraph> */}
                        </WaterMark>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default FinancialReport
