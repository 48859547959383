import React from 'react';
import PropTypes from 'prop-types';
import TweenOne from 'rc-tween-one';
import RcScrollElement from 'rc-scroll-anim/lib/ScrollElement';
import { Typography } from 'antd';
import QueueAnim from 'rc-queue-anim';

export default function Page3({ isMobile }) {
  return (
    <RcScrollElement id="page3-landing" className="content-wrapper page">
      <TweenOne
        key="image"
        className="image3 image-wrapper"
        animation={{ x: 0, opacity: 1, ease: 'easeOutQuad' }}
        style={{ transform: 'translateX(-100px)', opacity: 0 }}
      />
      <QueueAnim
        className="text-wrapper"
        key="text"
        type={isMobile ? 'bottom' : 'right'}
        leaveReverse
        style={{ top: '40%' }}
      >
        
        <Typography.Paragraph
            type={'secondary'}
            style={{
                fontSize: 24,
                marginBottom: 0
            }}
        >
            03
        </Typography.Paragraph>
        <br/>
        <h2 key="h2">Secure</h2>
        <p key="p" style={{ maxWidth: 280 }}>
        Our system uses encryption methods similar to Bitcoin and other digital currencies, ensuring robust fraud protection and keeping your transactions safe, even when operating offline.
        </p>
        {/* <div key="button">
          <a>
            <Button type="primary" size="large">
              了解更多
              <Icon type="right" />
            </Button>
          </a>
        </div> */}
      </QueueAnim>
    </RcScrollElement>
  );
}
Page3.propTypes = {
  isMobile: PropTypes.bool,
};
