// import { lazy } from 'react'
import IntroContent from '../../content/IntroContent.json'
// import MiddleBlockContent from '../../content/MiddleBlockContent.json'
import AboutContent from '../../content/AboutContent.json'
// import TrustedByContent from '../../content/TrustedByContent.json'
import MissionContent from '../../content/MissionContent.json'
// import ContactContent from '../../content/ContactContent.json'
import Container from '../../common/Container'
import ScrollToTop from '../../common/ScrollToTop'
import ContentBlock from '../../components/ContentBlock'
// import MiddleBlock from '../../components/MiddleBlock'
// import Testimonials from './Testimonials'
// import ContactForm from '../../components/ContactForm'
import Features from '@/app/components/Landing/Features'
import History from '@/app/components/History'
// import Page4 from '../Page4'
// import Page4Wrapper from '../Page4Wrapper'

// const Contact = lazy(() => import('../../components/ContactForm'))
// const MiddleBlock = lazy(() => import('../../components/MiddleBlock'))
// const Container = lazy(() => import('../../common/Container'))
// const ScrollToTop = lazy(() => import('../../common/ScrollToTop'))
// const ContentBlock = lazy(() => import('../../components/ContentBlock'))
// const Testimonials = lazy(() => import('./Testimonials'))

const Home = () => {
    return (
        <Container>
            <ScrollToTop />
            <ContentBlock
                type="right"
                title={IntroContent.title}
                content={IntroContent.text}
                button={IntroContent.button}
                icon="landing/1.jpg"
                id="intro"
            />
            {/* <MiddleBlock
                title={MiddleBlockContent.title}
                content={MiddleBlockContent.text}
                button={MiddleBlockContent.button}
            /> */}
                <Features />
            <ContentBlock
                type="left"
                title={AboutContent.title}
                content={AboutContent.text}
                section={AboutContent.section}
                icon="graphs.svg"
                id="about"
            />

            <ContentBlock
                type="right"
                title={MissionContent.title}
                content={MissionContent.text}
                icon="landing/mission.jpg"
                id="mission"
            />
            <History />
            {/* <ContentBlock
                type="left"
                title={TrustedByContent.title}
                content={TrustedByContent.text}
                section={TrustedByContent.section}
                icon="waving.svg"
                id="product"
            /> */}
            {/* <Testimonials type="right" icon="graphs.svg" id="testimonials" /> */}
            {/* <ContactForm
                title={ContactContent.title}
                content={ContactContent.text}
                id="contact"
            /> */}
        </Container>
    )
}

export default Home
